import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';

const Agents = () => {

    //
    const { getAgents, allagents, searchListAgents, nextAgents, lastreg } = useContext(DataContext);

    //
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [reloader, setReloader] = useState(1);

    //TEXTS IDIOMS
    var txt1 = "AGENTS";
    var txt2 = "NO RESULTS";
    var txt3 = "Search by name or nationality";
    var txt4 = "LOAD MORE";
    var txt5 = "SEARCH";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "AGENTES";
            txt2 = "SIN RESULTADOS";
            txt3 = "Buscar por nombre o nacionalidad";
            txt4 = "CARGAR MÁS";
            txt5 = "BUSCAR";
        }
        else{
            console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(() => {
        fillArray();
    },[ready, reloader]);

    //
    async function fillArray(){
        try {
            await getAgents();
            setList(allagents);
            console.log(list);
            console.log(list.length);
            nextP();
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

   //SEARCH ENGINE
   async function searching(){
        var a = document.getElementById("search_field").value.toLowerCase();
        var b = a.split(' ');
        var y = "agents";
        await searchListAgents(b, y);
        setList(allagents);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);
    }

    //NEXT PAGE
    async function nextP(){
        if(lastreg == undefined){
            console.log("Init");
        }else{
            await nextAgents('agents');
            setList(allagents);
        }  
    }

    //RESET
    function resetPage(){
        window.location.reload();
    }
    

    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt1}</h1>
                <div className="search_box">
                    <div className="search_area">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt3}
                        />
                        <button className="btn_min" onClick={searching}>{txt5}</button>
                    </div>
                </div>
                {
                list.length == 0
                ?
                <div className="loading_msg">{txt2}</div>
                :
                list.map((item, index) => (
                    <Link to={`/Agent/${ item.id }`}  key={item.id} className="list_item">
                        <div className="list_photo"> 
                            {
                                item.photoURL
                                ?
                                <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                :
                                null
                            } 
                        </div>
                        <div className="list_data">
                            <p className="list_name">{item.name + " " + item.lastname}</p>
                            <p className="list_pos">{item.profile + " | " + item.nationality}</p>
                        </div>
                        <div className="clear"></div>
                    </Link>
                ))}
                <div className="load_more_btn">
                    <button id="load_btn" className="btn_min" onClick={nextP}>{txt4}</button>
                    <button id="reset_btn" className="btn_min" onClick={resetPage}>RESET</button>
                </div>
            </div>

        </div>
        </>

    );


}

export default Agents;