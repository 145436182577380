import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Events = () => {

    //
    const { getEvents, allevents, searchListEvents, nextEvents, lastreg } = useContext(DataContext);

    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [reloader, setReloader] = useState(1);

    //TEXTS IDIOMS
    var txt1 = "EVENTS";
    var txt2 = "NO RESULTS";
    var txt3 = "Created by:";
    var txt4 = "Address: ";
    var txt5 = "Country: ";
    var txt6 = "LOAD MORE";
    var txt7 = "Search by event name or country";
    var txt8 = "SEARCH";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "EVENTOS";
            txt2 = "SIN RESULTADOS";
            txt3 = "Creado por:";
            txt4 = "Dirección: ";
            txt5 = "País: ";
            txt6 = "CARGAR MÁS";
            txt7 = "Buscar por nombre del evento o país";
            txt8 = "BUSCAR";
        }
        else{
            console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(() => {
        fillArray();
    },[ready, reloader]);

    //
    async function fillArray(){
        try {
            await getEvents();
            setList(allevents);
            console.log(list);
            console.log(list.length);
            nextP();
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    //SEARCH ENGINE
    async function searching(){
        var a = document.getElementById("search_field").value.toLowerCase();
        var b = a.split(' ');
        var y = "events";
        await searchListEvents(b, y);
        setList(allevents);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);
    }

    //NEXT PAGE
    async function nextP(){
        if(lastreg == undefined){
            console.log("Init")
        }else{
            await nextEvents('events');
            setList(allevents);
        }  
    }

    //RESET
    function resetPage(){
        window.location.reload();
    }

    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt1}</h1>
                <div className="search_box">
                    <div className="search_area">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt7}
                        />
                        <button className="btn_min" onClick={searching}>{txt8}</button>
                    </div>
                </div>
                {
                list.length == 0
                ?
                <div className="loading_msg">{txt2}</div>
                :
                list.map((item, index) => (
                    <div className="list_item2"  key={item.event_id}>
                        <div className="list_data">
                            <p className="list_name">{item.event}</p>
                            <Link to={`/${ item.creator_profile }/${ item.creator }`} className="textlink4">{txt3 + " " + item.creator_name}</Link>
                            <p className="list_pos"><strong>{txt4} </strong>{item.address}</p>
                            <p className="list_pos"><strong>{txt5} </strong>{item.nationality}</p>
                        </div>
                        <div className="clear"></div>
                    </div>
                ))}
                <div className="load_more_btn">
                    <button id="load_btn" className="btn_min" onClick={nextP}>{txt6}</button>
                    <button id="reset_btn" className="btn_min" onClick={resetPage}>RESET</button>
                </div>
            </div>

        </div>
        </>

    );


}

export default Events;