import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';
import { nanoid } from 'nanoid';

import Top from './top.js';

import { DataContext } from '../firebase/datacontext.js';

const OpenOffer = () => {
  
  //
  const { activeuser, createOffer, setOffer, createChat } = useContext(DataContext);

  //TEXTS IDIOMS
  var txt1 = "Write public offer";
  var txt2 = "OFFER:";
  var txt3 = "Maximum 100 characters.";
  var txt4 = "NATIONALITY OF PLAYER:";
  var txt5 = "Choose country";
  var txt6 = "COUNTRY OF OFFER:";
  var txt7 = "LEAGUE:";
  var txt8 = "Choose a league";
  var txt9 = "REQUIRED POSITION:";
  var txt10 = "Choose a position.";
  var txt11 = "Goalkeeper";
  var txt12 = "Defense";
  var txt13 = "Midfielder";
  var txt14 = "Forward";
  var txt15 = "SECONDARY POSITION:";
  var txt16 = "Choose a secondary position";
  var txt17 = "GENDER:";
  var txt18 = "Chose type";
  var txt19 = "Men's";
  var txt20 = "Women's";
  var txt21 = "SIDE";
  var txt22 = "Choose leg";
  var txt23 = "Right-footed";
  var txt24 = "Left-footed";
  var txt25 = "Ambidextrous";
  var txt26 = "AGE:";
  var txt27 = "HEIGHT (IN METERS):";
  var txt28 = "DESIRED PROJECT:";
  var txt29 = "Choose project";
  var txt30 = "100% sport";
  var txt31 = "Employment plus sport";
  var txt32 = "Study/Training plus sport";
  var txt33 = "MONTHLY SALARY:";
  var txt34 = "Choose monthly salary (USD)";
  var txt35 = "SEND";
  var txt36 = "CANCEL";
  var txt37 = "Proposal sent.";

  function changeLang(){
    var x = localStorage.getItem('idiom');

    if( x == "esp"){
      console.log("Lang: Español");
      txt1 = "Redactar oferta pública";
      txt2 = "OFERTA:";
      txt3 = "Máximo 100 caracteres.";
      txt4 = "NACIONALIDAD DEL JUGADOR:";
      txt5 = "Elige país";
      txt6 = "PAÍS DE LA OFERTA:";
      txt7 = "LIGA:";
      txt8 = "Elige una liga";
      txt9 = "POSICIÓN REQUERIDA:";
      txt10 = "Elige una posición";
      txt11 = "Portero";
      txt12 = "Defensa";
      txt13 = "Centrocampista";
      txt14 = "Delantero";
      txt15 = "POSICIÓN SECUNDARIA:";
      txt16 = "Elige una posición secundaria";
      txt17 = "RAMA:";
      txt18 = "Elige rama";
      txt19 = "Masculino";
      txt20 = "Femenil";
      txt21 = "LATERALIDAD";
      txt22 = "Elige pierna";
      txt23 = "Diestro";
      txt24 = "Zurdo";
      txt25 = "Ambidiestro";
      txt26 = "EDAD:";
      txt27 = "ALTURA (EN METROS):";
      txt28 = "PROYECTO DESEADO:";
      txt29 = "Elige proyecto";
      txt30 = "100% deporte";
      txt31 = "Empleo más deporte";
      txt32 = "Estudio/Formación más deporte";
      txt33 = "SUELDO MENSUAL:";
      txt34 = "Elige sueldo mensual (USD)";
      txt35 = "ENVIAR";
      txt36 = "CANCELAR";
      txt37 = "Propuesta enviada.";
    }
    else{
      console.log("Lang: English");
    }
  }

  changeLang();


  //USE EFFECT
  useEffect(() => {
    
  },[]);

  //
  const navigate = useNavigate();

  function cancelEdit(){
    navigate('/User')
  }

  //
  var d = new Date();
  const formik = useFormik ({
    initialValues:{
        offer: '',
        nationality: '',
        offer_country: '',
        league: '',
        position: '',
        sec_position: '',
        gender: '',
        dominant_leg: '',
        age: '',
        height: '',
        project:'',
        pay:'',
        update: d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3),
    },
    onSubmit: reg =>{
      reg.tags = [reg.offer_country.toLowerCase(), reg.league.toLowerCase(), reg.nationality.toLowerCase(), reg.position.toLowerCase(), reg.sec_position.toLowerCase()];
      reg.creator = activeuser.key_id;
      reg.creator_name = activeuser.name + " " + activeuser.lastname;
      reg.receptor = "open";
      reg.receptor_name = "Open offer";
      reg.chatid = nanoid(12);
      reg.messages = [];
      console.log(reg);
      createOffer(reg.chatid, reg);
      //setOffer(activeuser.key_id, reg);
      //createChat(reg.chatid, reg.offer);
      alert(txt37);
    }
  })

  return (
    <>
    <Top/>
    <div className="Main">

     <div className="log_box">
      <h1>{txt1}</h1>
        <form onSubmit={formik.handleSubmit} className="fields">
          
          <div className="field">
            <label htmlFor="bio">{txt2}</label>
            <textarea
              id="offer"
              name="offer"
              maxLength="200"
              value={formik.values.offer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt3}
            >
            </textarea>
          </div>
          <div className="field">
            <label htmlFor="nationality">{txt4}</label>
            <select 
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                <option value="">{txt5}</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="offer_country">{txt6}</label>
            <select 
              id="offer_country"
              name="offer_country"
              value={formik.values.offer_country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                <option value="">{txt5}</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="league">{txt7}</label>
            <select 
              id="league"
              name="league"
              value={formik.values.league}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                <option value="">{txt8}</option>

                <option value="Albania - Superliga">Albania - Superliga</option>
                <option value="Algeria - Ligue 1">Algeria - Ligue 1</option>
                <option value="Algeria - Ligue 2">Algeria - Ligue 2</option>
                <option value="Algeria - U21 League">Algeria - U21 League</option>
                <option value="Angola - Girabola">Angola - Girabola</option>
                <option value="Argentina - Primera División">Argentina - Primera División</option>
                <option value="Argentina - Prim B Nacional">Argentina - Prim B Nacional</option>
                <option value="Argentina - Prim B Metro">Argentina - Prim B Metro</option>
                <option value="Argentina - Torneo Federal A">Argentina - Torneo Federal A</option>
                <option value="Argentina - Primera C">Argentina - Primera C</option>
                <option value="Argentina - Primera D">Argentina - Primera D</option>
                <option value="Argentina - Torneo Regional Federal Amateur">Argentina - Torneo Regional Federal Amateur</option>
                <option value="Argentina - Campeonato de Fútbol Femenino">Argentina - Campeonato de Fútbol Femenino</option>
                <option value="Argentina - Reserve League">Argentina - Reserve League</option>
                <option value="Armenia - Armenian Premier League">Armenia - Armenian Premier League</option>
                <option value="Australia - A-League">Australia - A-League</option>
                <option value="Australia - Northern NSW NPL">Austrlia - Northern NSW NPL</option>
                <option value="Australia - Queensland NPL">Austrlia - Queensland NPL</option>
                <option value="Australia - Victoria NPL">Austrlia - Victoria NPL</option>
                <option value="Australia - New South Wales NPL">Austrlia - New South Wales NPL</option>
                <option value="Australia - Tasmania NPL">Austrlia - Tasmania NPL</option>
                <option value="Australia - South Australia NPL">Austrlia - South Australia NPL</option>
                <option value="Australia - Western Australia NPL">Austrlia - Western Australia NPL</option>
                <option value="Australia - Capital Territory NPL">Austrlia - Capital Territory NPL</option>
                <option value="Austria - Bundesliga">Austria - Bundesliga</option>
                <option value="Austria - 2. Liga">Austria - 2. Liga</option>
                <option value="Belarus - Vysheyshaya Liga">Belarus - Vysheyshaya Liga</option>
                <option value="Belarus - First League">Belarus - First League</option>
                <option value="Belgium - Pro League">Belgium - Pro League</option>
                <option value="Belgium - First Division B">Belgium - First Division B</option>
                <option value="Bolivia - LFBP">Bolivia - LFBP</option>
                <option value="Bolivia - Liga Nacional B">Bolivia - Liga Nacional B</option>
                <option value="Bosnia and Herzegovina - Premier League of Bosnia">Bosnia and Herzegovina - Premier League of Bosnia</option>
                <option value="Botswana - Botswana Premier League">Botswana - Botswana Premier League</option>
                <option value="Brazil">Brazil</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Chile">Chile</option>
                <option value="Colombia">Colombia</option>
                <option value="Congo">Congo</option>
                <option value="Congo DR">Congo DR</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Croatia">Croatia</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="England">England</option>
                <option value="Estonia">Estonia</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="FYR Macedonia">FYR Macedonia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                
                <option value="Greece">Greece</option>
               
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
               
                <option value="Haiti">Haiti</option>
               
                <option value="Honduras">Honduras</option>
               
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran">Iran</option>
               
                
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>


                <option value="Ivory Coast">Ivory Coast</option>
                <option value="Japan">Japan</option>
               
                <option value="Kenya">Kenya</option>
               
                <option value="Latvia">Latvia</option>
                
                <option value="Lithuania">Lithuania</option>
                
                <option value="Malaysia">Malaysia</option>
                
                <option value="Mali">Mali</option>
                
                <option value="Mexico">Mexico</option>
               
                <option value="Moldova">Moldova</option>
               
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
               
                <option value="Netherlands">Netherlands</option>
               
              
                <option value="Nigeria">Nigeria</option>
              
                <option value="Northern Ireland">Northern Ireland</option>
                <option value="Norway">Norway</option>
               
                <option value="Panama">Panama</option>
               
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                
                <option value="Qatar">Qatar</option>
                <option value="Republic of Ireland">Republic of Ireland</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Scotland">Scotland</option>
                <option value="Serbia">Serbia</option>
                
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
               
                <option value="South Africa">South Africa</option>
                <option value="South Korea">South Korea</option>
                <option value="Spain">Spain</option>
                
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>

                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="Ukraine">Ukraine</option>
                
               
                <option value="United States of America">United States of America</option>
               
                <option value="Uruguay">Uruguay</option>
                
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>

                <option value="Walles">Walles</option>
              
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              
            </select>
          </div>
          <div className="field">
            <label htmlFor="position">{txt9}</label>
            <select 
              id="position"
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt10}</option>
              <option value="Goalkeeper/Portero">{txt11}</option>
              <option value="Defense/Defensa">{txt12}</option>
              <option value="Midfielder/Centrocampista">{txt13}</option>
              <option value="Forward/Delantero">{txt14}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="sec_position">{txt15}</label>
            <select 
              id="sec_position"
              name="sec_position"
              value={formik.values.sec_position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt16}</option>
              <option value="Goalkeeper/Portero">{txt11}</option>
              <option value="Defense/Defensa">{txt12}</option>
              <option value="Midfielder/Centrocampista">{txt13}</option>
              <option value="Forward/Delantero">{txt14}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="gender">{txt17}</label>
            <select 
              id="gender"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt18}</option>
              <option value="Men's">{txt19}</option>
              <option value="Women's">{txt20}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="dominant_leg">{txt21}</label>
            <select 
              id="dominant_leg"
              name="dominant_leg"
              value={formik.values.dominant_leg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt22}</option>
              <option value="Right-footed">{txt23}</option>
              <option value="Left-footed">{txt24}</option>
              <option value="Ambidextrous">{txt25}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="age">{txt26}</label>
            <input
            type="text"
            id="age"
            name="age"
            maxLength="2"
            value={formik.values.age}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}>
            </input>
          </div>
          <div className="field">
            <label htmlFor="height">{txt27}</label>
            <input
              type="number"
              id="height"
              name="height"
              step=".01"
              min="1.3"
              max="2.5"
              placeholder="1.5"
              value={formik.values.height}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="project">{txt28}</label>
            <select 
              id="project"
              name="project"
              value={formik.values.project}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt29}</option>
              <option value="100% sport">{txt30}</option>
              <option value="Employment plus sport">{txt31}</option>
              <option value="Training plus sport">{txt32}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="pay">{txt33}</label>
            <select 
              id="pay"
              name="pay"
              value={formik.values.pay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt34}</option>
              <option value="0 - 500">0 - 500</option>
              <option value="500 - 1000">500 - 1000</option>
              <option value="1000 - 2000">1000 - 2000</option>
              <option value="2000 - 3000">2000 - 3000</option>
              <option value="3000 - 4000">3000 - 4000</option>
              <option value="4000 - 5000">4000 - 5000</option>
            </select>
          </div>
          <button type="submit" className="btn">{txt35}</button>
        </form>
        <button className="btn_cancel" onClick={cancelEdit}>{txt36}</button>
     </div>
    </div>
    </>

  );
}

export default OpenOffer;