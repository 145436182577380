import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Wall = () => {

    //STATES
    const { wallplayers, getWall, activeuser, getMessages, filtermessages } = useContext(DataContext);
    const [ready, setReady] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "RECENT ACTIVITY";
    var txt2 = "NO RESULTS:";
    var txt3 = "New registered user";
    var txt4 = "Uploaded a video";
    var txt5 = "Added a team to the resume";
    var txt6 = "New status:";
    var txt7 = "I'm not in any club and I'm available immediately";
    var txt8 = "I am in a club and I am available immediately without compensation (open contract)";
    var txt9 = "I am in a club and I am available with compensation (buy out)";
    var txt10 = "I'm in a club and I'm available under conditions";
    var txt11 = "I'm in a club and I'm open to new offers";
    var txt12 = "Modified profile";
    var txt13 = "Has a new agent";
 
    function changeLang(){
        var x = localStorage.getItem('idiom');
 
        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "ACTIVIDAD RECIENTE";
            txt2 = "SIN RESULTADOS:";
            txt3 = "Nuevo usuario registrado";
            txt4 = "Subió un video";
            txt5 = "Agregó un equipo a su Currículum";
            txt6 = "Nuevo estatus:";
            txt7 = "No estoy en ningún club y estoy disponible inmediatamente";
            txt8 = "Estoy en un club y estoy disponible inmediatamente sin indemnización (open contract)";
            txt9 = "Estoy en un club y estoy disponible con indemnización (buy out)";
            txt10 = "Estoy en un club y estoy disponible bajo condiciones";
            txt11 = "Estoy en un club y estoy abierto a nuevas ofertas";
            txt12 = "Modificó su perfil";
            txt13 = "Tiene un nuevo agente";
        }
        else{
            console.log("Lang: English");
        }
     }
 
    changeLang();

    //USE EFFECT
    useEffect(() => {
        fillArray();
    },[ready]);

    
    //
    async function fillArray(){
        try {
            await getWall();
            //await getMessages();
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    //
    function checkIdiom(){
        console.log(localStorage.getItem('idiom'));
    }

    //
    function clearIdiom(){
        localStorage.clear();
    }

    return(
        <>
        <Top/>
        <div className="main">

            <Link to={'/User'} className="profile_wall">
                <div className="profile_photo_wall">
                     <div className="profile_photo_im_wall">
                        {
                            activeuser.photoURL
                            ?
                            <img className="profile_pic_wall" src={activeuser.photoURL} alt="Foto de perfil"/>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="profile_data">
                    <h1>{activeuser.name + ' ' + activeuser.lastname}</h1>
                    <p>{activeuser.profile ? activeuser.profile : '--'} | {activeuser.nationality ? activeuser.nationality : '--'}</p>
                </div>
            </Link>
                                  
            

            <div className="list_box">
                <h1>{txt1}</h1>
                {
                wallplayers.length == 0
                ?
                <div className="loading_msg">{txt2}</div>
                :
                wallplayers.map((item, index) => (
                    <div key={item.id} className="list_item2">
                        <div className="list_photo"> 
                            {
                                item.photoURL
                                ?
                                <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                :
                                null
                            }
                        </div>
                        <div className="list_data">
                            <Link to={`/${ item.profile}/${ item.id }`}  className="list_name2">{item.name + " " + item.lastname}</Link>
                            <p className="list_pos">{item.profile + " | " + item.nationality}</p>
                            <div className="wall_item">
                                {
                                    item.mod_type == "1"
                                    ?
                                    <p className="list_new">{txt3}</p>
                                    :
                                    null
                                }
                                {
                                    item.mod_type == "2"
                                    ?
                                    <div>
                                        <p className="list_new">{txt4}</p>
                                        <div dangerouslySetInnerHTML={{__html: item.videos[0] }} />
                                    </div>
                                    :
                                    null
                                }
                                {
                                    item.mod_type == "3"
                                    ?
                                    <p className="list_new">{txt5}</p>
                                    :
                                    null
                                }
                                {
                                    item.mod_type == "4"
                                    ?
                                    <div>
                                         <p className="list_new">{txt6}</p>
                                         
                                        {
                                                item.status == "1"
                                                ?
                                                <p className="list_pos">{txt7}</p>
                                                :
                                                null
                                        }  
                                        {
                                                item.status == "2"
                                                ?
                                                <p className="list_pos">{txt8}</p>
                                                :
                                                null
                                        } 
                                        {
                                                item.status == "3"
                                                ?
                                                <p className="list_pos">{txt9}</p>
                                                :
                                                null
                                        }
                                        {
                                                item.status == "4"
                                                ?
                                                <p className="list_pos">{txt10}</p>
                                                :
                                                null
                                        }
                                        {
                                                item.status == "5"
                                                ?
                                                <p className="list_pos">{txt11}</p>
                                                :
                                                null
                                        }                 

                                    </div>
                                    :
                                    null
                                }
                                {
                                    item.mod_type == "5"
                                    ?
                                    <p className="list_new">{txt13}</p>
                                    :
                                    null
                                }
                                {
                                    item.mod_type == "6"
                                    ?
                                    <p className="list_new">{txt12}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                ))}
            </div>
        </div>
        </>

    );


}

export default Wall;