import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import Logo from '../images/logo.png';

import { DataContext } from '../firebase/datacontext.js';

const Login = () => {
  
  //TEXTS IDIOMS
  var txt1 = "Log in";
  var txt2 = "ENTER";
  var txt3 = "New user?";
  var txt4 = "REGISTER";
  var txt5 = "FORGOT YOUR PASSWORD?";

  function changeLang(){
      var x = localStorage.getItem('idiom');

      if( x == "esp"){
          console.log("Lang: Español");
          txt1 = "Iniciar sesión";
          txt2 = "ENTRAR";
          txt3 = "¿Nuevo usuario?";
          txt4 = "REGISTRARSE";
          txt5 = "¿OLVIDÓ SU PASSWORD?";
      }
      else{
          console.log("Lang: English");
      }
  }

  changeLang();

  
  //
  const { signIn, logOut, logGoogle } = useContext(DataContext);

  //
  const [mail, setMail] = useState();
  const [pass, setPass] = useState();

  return (
    <div className="intro">

      <div className="log_box">
        <img src={Logo} alt="logo"/>
        <h1>{txt1}</h1>
        <div className="fields">
          <div className="field">
            <label htmlFor="email">EMAIL:</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={e => setMail(e.target.value)}
            />
          </div>
          <div className="field">
            <label htmlFor="lastname">PASSWORD:</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={e => setPass(e.target.value)}
            />
          </div>
        </div>
        <button className="btn" onClick={() => signIn(mail, pass)}>{txt2}</button>
        <Link className="textlink" to="/LoginReset">{txt5}</Link>
        <div className="log_box2">
          <p>{txt3}</p>
          <Link className="textlink" to="/Register">{txt4}</Link>
        </div>
      </div>
    
    </div>

  );
}

export default Login;