import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import {useFormik} from 'formik';
import { nanoid } from 'nanoid';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';

const Player = () => {

    //EXTRAER ID DEL JUGADOR
    var u = window.location.pathname;
    var l = u.substr(8);
    console.log(u);
    console.log(l);

    //IMPORTACIÓN DE FUNCIONES
    const { premium, premiumsub, getPlayer, player, getUser, activeuser, setAg, createOffer, followPl, getPlayerAgent, playeragent, createChat, buySub } = useContext(DataContext);


    //
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [age, setAge] = useState(0);

    //
    const [isMine, setIsmine] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "Proposal for ";
    var txt2 = "OFFER";
    var txt3 = "Maximum 100 characters";
    var txt4 = "OFFER COUNTRY:";
    var txt5 = "Choose country";
    var txt6 = "LEAGUE:";
    var txt7 = "Choose a league";
    var txt8 = "DESIRED PROJECT:";
    var txt9 = "Choose project";
    var txt10 = "100% sport";
    var txt11 = "Employment plus sport"; 
    var txt12 = "Study/Training plus sport";
    var txt13 = "MONTHLY SALARY:";
    var txt14 = "Choose monthly salary (USD)";
    var txt15 = "SEND";
    var txt16 = "CANCEL";
    var txt17 = "You confirm that you are an agent of:";
    var txt18 = "YES, I CONFIRM";
    var txt19 = "Verified";
    var txt20 = "Not verified";
    var txt21 = "I am not in any club and I am available immediately";
    var txt22 = "I am in a club and I am available immediately without indemnity (open contract)";
    var txt23 = "I am in a club and I am available with severance pay (buy out)";
    var txt24 = "I am in a club and I am available under conditions";
    var txt25 = "I am in a club and I am open to new offers"
    var txt26 = "Availability unspecified";
    var txt27 = "Available on: ";
    var txt28 = "Unspecified";
    var txt29 = "Weight:";
    var txt30 = "Height:";
    var txt31 = "Side:";
    var txt32 = "Type:";
    var txt33 = "FOLLOWING";
    var txt34 = "FOLLOW";
    var txt35 = "YOU ARE HIS/HER AGENT";
    var txt36 = "BECOME A PREMIUM USER TO CONTACT!";
    var txt37 = "I AM HIS/HER AGENT";
    var txt38 = "MESSAGE";
    var txt39 = "WRITE PROPOSAL";
    var txt40 = "Proposal sent.";
    var txt41 = "This player already has an agent representing him/her.";
    var txt42 = "Agent: ";
    var txt43 = "Free agent";
    var txt44 = "Pending verification";
    var txt45 = "Date of birth:";
    var txt46 = "Age:";
    var txt47 = "Current team:";
    var txt48 = "No team";
    var txt49 = "Currículum";
    var txt50 = "Start:";
    var txt51 = "Exit:";
    var txt52 = "No Teams.";
    var txt53 = "Videos";
    var txt54 = "No videos yet.";
    var txt55 = "ARE YOU HIS/HER AGENT? BECOME A PREMIUM MEMBER!";
    var txt56 = "YOU MUST VERIFY YOUR IDENTITY TO CONTACT THE PLAYER";
 
    function changeLang(){
        var x = localStorage.getItem('idiom');
 
        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "Propuesta para ";
            txt2 = "OFERTA";
            txt3 = "Máximo 100 caracteres";
            txt4 = "PAIS DE LA OFERTA:";
            txt5 = "Elige país";
            txt6 = "LIGA:";
            txt7 = "Elige una liga";
            txt8 = "PROYECTO DESEADO:";
            txt9 = "Elige proyecto";
            txt10 = "100% deporte";
            txt11 = "Empleo más deporte";
            txt12 = "Estudio/Formación más deporte";
            txt13 = "SUELDO MENSUAL:";
            txt14 = "Elige sueldo mensual (USD)";
            txt15 = "ENVIAR";
            txt16 = "CANCELAR";
            txt17 = "Confirmas que eres agente de:";
            txt18 = "SÍ, CONFIRMO";
            txt19 = "Verificado";
            txt20 = "No verificado";
            txt21 = "No estoy en ningún club y estoy disponible inmediatamente";
            txt22 = "Estoy en un club y estoy disponible inmediatamente sin indemnización (open contract)";
            txt23 = "Estoy en un club y estoy disponible con indemnización (buy out)";
            txt24 = "Estoy en un club y estoy disponible bajo condiciones";
            txt25 = "Estoy en un club y estoy abierto a nuevas ofertas";
            txt26 = "Disponibilidas sin especificar";
            txt27 = "Disponible el: ";
            txt28 = "Sin especificar";
            txt29 = "Peso:";
            txt30 = "Altura:";
            txt31 = "Lateralidad:";
            txt32 = "Rama:";
            txt33 = "SIGUIENDO";
            txt34 = "SEGUIR";
            txt35 = "ERES SU AGENTE";
            txt36 = "¡CONVIÉRTETE EN USUARIO PREMIUM PARA CONTACTARLO!";
            txt37 = "SOY SU AGENTE";
            txt38 = "MENSAJE";
            txt39 = "REDACTAR PROPUESTA";
            txt40 = "Propuesta enviada.";
            txt41 = "Este jugador ya tiene un agente que lo representa.";
            txt42 = "Agente: ";
            txt43 = "Libre de agente";
            txt44 = "Pendiente por verificar";
            txt45 = "Fecha de nacimiento:";
            txt46 = "Edad:";
            txt47 = "Equipo actual:";
            txt48 = "Sin equipo";
            txt49 = "Currículum";
            txt50 = "Inicio:";
            txt51 = "Salida:";
            txt52 = "No hay equipos.";
            txt53 = "Videos";
            txt54 = "No hay videos aún.";
            txt55 = "¿ERES SU AGENTE? ¡CONVIÉRTETE EN USUARIO PREMIUM!";
            txt56 = "USTED DEBE DE VERIFICAR SU IDENTIDAD PARA CONTACTAR AL JUGADOR";
        }
        else{
            console.log("Lang: English");
        }
    }
 
    changeLang();

    //USE EFFECT
    useEffect(async () => {
        await getPlayer(l);
        fillArray();
        getAge(player.birthday);  
    },[ready, age]);


    //
    async function fillArray(){
        try {
            await getPlayerAgent(player.agent);
            setList(playeragent);
            console.log(list)
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    //
    function closePop(p){
        document.getElementById(p).style.display = "none";
    }

    //
    function openPop(){
        document.getElementById('popup1').style.display = "flex";
    }

    //
    function testRep(){
        if(player.agent){
            alert("Este jugador ya tiene un agente que lo representa.")
        }
        else{
            document.getElementById('popup2').style.display = "flex";
        }
    }

   //
    async function setRep(){
       await setAg(player.key_id, activeuser.key_id);
        setTimeout(() => {
            document.getElementById('popup2').style.display = "none";
        }, 1000);
    }

    //
    async function setFollow(){
        await followPl(activeuser.key_id, player.key_id, activeuser.ref);
        //console.log(activeuser.ref)
    }

    //
    function setChat(){
        var creator_name = activeuser.name + ' ' + activeuser.lastname;
        var receptor_name = player.name + ' ' + player.lastname;
        var creator_profile = activeuser.profile;
        var receptor_profile = player.profile;
       
        createChat( nanoid(12), activeuser.key_id, player.key_id, creator_name, receptor_name, creator_profile, receptor_profile );
    }


    //
    const formik = useFormik ({
        initialValues:{
            offer: '',
            offer_country: '',
            league: '',
            project:'',
            pay:''
        },
        validateOnChange: false,
        validateOnBlur:false,
        onSubmit: reg =>{
            reg.creator = activeuser.key_id;
            reg.creator_name = activeuser.name + " " + activeuser.lastname;
            reg.creator_profile = activeuser.profile;
            reg.receptor = player.key_id;
            reg.receptor_name = player.name + " " + player.lastname;
            reg.receptor_profile = player.profile;
            reg.chatid = nanoid(12);
            reg.messages = [];
            console.log(reg);
            createOffer(reg.chatid, reg);
            closePop('popup1');
            alert(txt40);
        }
    })

    //
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var aged = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            aged--;
        }
        setAge(aged);
        console.log(age);
    }

    
    
    return(
        <>
        <div className="popup" id="popup1">
            <div className="popup_box2">
                <p className="popup_t2">{txt1 + ' ' + player.name + ' ' + player.lastname}</p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="field">
                        <label htmlFor="bio">{txt2}</label>
                        <textarea
                        id="offer"
                        name="offer"
                        maxLength="200"
                        value={formik.values.offer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={txt3}
                        >
                        </textarea>
                    </div>
                    <div className="field">
                        <label htmlFor="offer_country">{txt4}</label>
                        <select 
                        id="offer_country"
                        name="offer_country"
                        value={formik.values.offer_country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                            <option value="">{txt5}</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                    </div>
                    <div className="field">
                        <label htmlFor="league">{txt6}</label>
                        <select 
                        id="league"
                        name="league"
                        value={formik.values.league}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        <option value="">{txt7}</option>
                        <option value="Premier League">Premier League</option>
                        <option value="La Liga">La Liga</option>
                        <option value="Serie A">Serie A</option>
                        <option value="Bundesliga">Bundesliga</option>
                        <option value="Ligue 1">Ligue 1</option>
                        </select>
                    </div>
                    <div className="field">
                        <label htmlFor="project">{txt8}</label>
                        <select 
                        id="project"
                        name="project"
                        value={formik.values.project}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        <option value="">{txt9}</option>
                        <option value="100% sport">{txt10}</option>
                        <option value="Employment plus sport">{txt11}</option>
                        <option value="Training plus sport">{txt12}</option>
                        </select>
                    </div>
                    <div className="field">
                        <label htmlFor="pay">{txt13}</label>
                        <select 
                        id="pay"
                        name="pay"
                        value={formik.values.pay}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        <option value="">{txt14}</option>
                        <option value="0 - 500">0 - 500</option>
                        <option value="500 - 1000">500 - 1000</option>
                        <option value="1000 - 2000">1000 - 2000</option>
                        <option value="2000 - 3000">2000 - 3000</option>
                        <option value="3000 - 4000">3000 - 4000</option>
                        <option value="4000 - 5000">4000 - 5000</option>
                        </select>
                    </div>
                    <button className="btn" type="submit">{txt15}</button>
                </form>
                <button className="btn_cancel" onClick={()=> closePop('popup1')}>{txt16}</button>
            </div>
        </div>

        <div className="popup" id="popup2">
            <div className="popup_box2">
                <p className="popup_t2">{txt17}<br/><span>{player.name + ' ' + player.lastname}</span></p>
                <button className="btn" onClick={setRep}>{txt18}</button>
                <button className="btn_cancel" onClick={()=> closePop('popup2')}>{txt16}</button>
            </div>
        </div>

        <Top/>
        <div className="main">
            <div className="profile_box">
                <div className="profile_top">
                    <div className="profile_photo">
                        <div className="profile_photo_im">
                            {
                                player.photoURL
                                ?
                                <img className="profile_pic" src={player.photoURL} alt="Foto de perfil"/>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="profile_data">
                        <h1>{player.name + ' ' + player.lastname}</h1>
                        <p>{player.position ? player.position : '--'} | {player.nationality ? player.nationality : '--'}</p>
                        <div className="verificated_line">
                            {
                                player.verificated == "1"
                                ?
                                <p className="verificated"><span>&#10003; </span>{txt19}</p>
                                :
                                <p className="noverificated">{txt20}</p>
                            }
                        </div>
                        <div className="profile_available">
                            {
                                player.status 
                                ? 
                                <div className="profile_available_stat">
                                    {
                                       player.status == "1"
                                        ?
                                        <p><strong>{txt21}</strong></p>
                                        :
                                        null
                                    }  
                                    {
                                        player.status == "2"
                                        ?
                                        <p><strong>{txt22}</strong></p>
                                        :
                                        null
                                    } 
                                    {
                                        player.status == "3"
                                        ?
                                        <p><strong>{txt23}</strong></p>
                                        :
                                        null
                                    }
                                    {
                                        player.status == "4"
                                        ?
                                        <p><strong>{txt24}</strong></p>
                                        :
                                        null
                                    }
                                    {
                                        player.status == "5"
                                        ?
                                        <p><strong>{txt25}</strong></p>
                                        :
                                        null
                                    }                   
                                </div>
                                : 
                                <p className="profile_available_notset"><strong>{txt26}</strong></p>
                            }
                            <p className="profile_available_date">{txt27} {player.status_date ? player.status_date : txt28}</p>
                        </div>
                        <div className="profile_nums">
                            <div className="profile_num">
                                <p className="profile_num_t">{txt29}</p>
                                <p className="profile_num_d">{player.weight ? player.weight + " " + "kg" : '--'}</p>
                            </div>
                            <div className="profile_num">
                                <p className="profile_num_t">{txt30}</p>    
                                <p className="profile_num_d">{player.height ? player.height + " " + "m" : '--'}</p>
                            </div>
                            <div className="profile_num">
                                <p className="profile_num_t">{txt31}</p>
                                <p className="profile_num_d">{player.dominant_leg ? player.dominant_leg : '--'}</p>
                            </div>
                            <div className="profile_num">
                                <p className="profile_num_t">{txt32}</p>
                                <p className="profile_num_d">{player.gender ? player.gender : '--'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                    {
                        activeuser.profile != "Player"
                        ?
                        <div>
                            <div className="follow_box">
                                {
                                    player.follower && player.follower.some(f => f === activeuser.key_id)
                                    ?
                                    <p className="isyour">{txt33}</p>
                                    :
                                    <button className="btn_min btn_lat" onClick={setFollow}>{txt34}</button>
                                }
                            </div>
                            {
                                activeuser.profile == "Agent"
                                ?
                                <div>
                                    { 
                                        player.agent == activeuser.key_id
                                        ?
                                        <p className="isyour">{txt35}</p>
                                        :
                                        <div className="div_inline">
                                            {
                                            (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                            ?
                                            <button className="btn_min btn_lat" onClick={testRep}>{txt37}</button>
                                            :
                                            <button className="btn_min_gold2 btn_lat" onClick={buySub}>{txt55}</button>
                                            }
                                        </div>
    
                                    }
                                    {
                                        activeuser.verificated == "1"
                                        ?
                                        <div className="msgs_buttons">
                                            <button className="btn_min btn_lat" onClick={openPop}>{txt39}</button>
                                            <button className="btn_min btn_lat" onClick={setChat}>{txt38}</button>
                                        </div>
                                        :
                                        <p className="error_sub">{txt56}</p>
                                    }
                                </div>
                                :
                                <div>
                                    {
                                       (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                        ?
                                        <div className="msgs_buttons">
                                            <button className="btn_min btn_lat" onClick={openPop}>{txt39}</button>
                                            <button className="btn_min btn_lat" onClick={setChat}>{txt38}</button>
                                        </div>
                                        :
                                        <button className="btn_min_gold2 btn_lat" onClick={buySub}>{txt36}</button>
                                    }   
                                </div>
                            }
                        
                        </div>
                        
                        :

                        null
                    }   
                   
                </div>
                
                <div className="profile_timeline">

                    <div className="profile_bio">
                        <p>{player.bio ? player.bio : '--'}</p>
                        <div className="profile_bio2">
                            {
                                    list.length == 0
                                    ?
                                    <p><strong>{txt42}</strong>{txt43}</p>
                                    :
                                    <div>
                                    {
                                        activeuser.validated_agent != "0"
                                        ?
                                        <p><strong>{txt42}</strong><Link className='textlink' to={`/Agent/${ list[0].key_id}`}>{list[0].name + ' ' + list[0].lastname}</Link></p>
                                        :
                                        <p><strong>{txt42}</strong>{txt44}</p>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    
                    <div className="profile_stats">
                        <div className="stat">
                            <p className="stat_t">{txt45}</p>
                            <p className="stat_d">{player.birthday ? player.birthday : '--'}</p>
                        </div>
                        <div className="stat">
                            <p className="stat_t">{txt46}</p>
                            <p className="stat_d">{ age === 0 ? null : age }</p>
                        </div>
                        <div className="stat">
                            {
                                player.teams && player.teams.length > 0
                                ?
                                player.teams.filter(x => x.team_end === '').map((item, index) => (
                                    <div key={index}>
                                        <p className="stat_t">{txt47}</p>
                                        <p className="stat_d">{item.team_name}</p>
                                    </div>
                                ))
                                :
                                <p className="stat_t">{txt48}</p>
                            }    
                        </div>   
                    </div>

                    <div className="profile_boxes">
                        <h2>{txt49}</h2>    
                        <div className="teams_list">
                            {
                                player.teams && player.teams.length > 1
                                ?
                                player.teams.slice()
                                .sort((a, b) => {
                                    if (a.team_end === "" && b.team_end !== "") {
                                        return -1; // Colocar a al principio
                                    } else if (a.team_end !== "" && b.team_end === "") {
                                        return 1; // Colocar b al principio
                                    } else {
                                        return new Date(b.team_end) - new Date(a.team_end); // Ordenar por fechas
                                    }
                                }).map((item, index) => (
                                    <div key={index} className="team_element">
                                        <p className="list_name">{item.team_name}</p>
                                        <p className="list_pos">{item.team_position}</p>
                                        <p className="list_pos"> {txt50 + ' ' + item.team_init} | {txt51 + ' ' + item.team_end}</p>
                                    </div>
                                ))
                                :
                                <div>{txt52}</div>
                            }
                        </div>
                    </div>

                    <div className="profile_boxes">
                        <h2>{txt53}</h2>
                        <div className="videos_list">
                            {
                            player.videos && player.videos.length > 0
                            ?
                            player.videos.map((item, index) => (
                                <div key={index} className="video_element">
                                    <div dangerouslySetInnerHTML={{__html: item }} />
                                </div>
                            ))
                            :
                            <div>{txt54}</div>
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>

                <div className="clear"></div>

            </div>

        </div>
        </>

    );
}

export default Player;