import React, {useEffect, useState, useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {useFormik} from 'formik';

import { DataContext } from '../firebase/datacontext.js';

import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

import Top from '../elementos/top.js';

const User = () => {

    //
    const { activeuser, premium, premiumsub, addVideoplayer, addVideocoach, quitVideoplayer, quitVideocoach, addTeamPl, addTeamAg, addTeamCo, quitTeamPl, quitTeamAg, quitTeamCo, uploadPhotoMin, getAgentPlayers, agentplayers, getPlayerAgent, playeragent, getOffersPlayer, getOffersAgent, alloffers, saveStatus, getMessages, buySub, accAg, delAg, getFollowingPlayers, flplayers, getFollowerAgents, flagents, getFollowerCoaches, flcoaches, getFollowerClubs, flclubs } = useContext(DataContext);

    //
    const [videolink, setVideolink] = useState('');

    //
    const [image, setImage] = useState(null);
    const [imageFull, setImagefull] = useState(null);

    //
    const [ready, setReady] = useState(false);
    const [list, setList] = useState([]);
    const [listoffers, setListoffers] = useState([]);
    const [age, setAge] = useState(0);

    //STATES
    const [reloader, setReloader] = useState(1);

    //TEXTS IDIOMS
    var txt1 = "Upload profile picture";
    var txt2 = "Crop";
    var txt3 = "UPLOAD";
    var txt4 = "CANCEL";
    var txt5 = "Unsubscribe";
    var txt6 = "Do you confirm to cancel your subscription?";
    var txt7 = "YES, I CONFIRM";
    var txt8 = "Change your availability";
    var txt9 = "I am not in any club and I am available immediately";
    var txt10 = "I am in a club and I am available immediately without compensation (open contract)"; 
    var txt11 = "I am in a club and I am available with severance pay (buy out)";
    var txt12 = "I am in a club and I am available under conditions";
    var txt13 = "I am in a club and I am open to new offers";
    var txt14 = "DATE OF AVAILABILITY:";
    var txt15 = "ACCEPT";
    var txt16 = "Verify agent's identity";
    var txt17 = "For the safety of our users and young players, you are required to verify your identity. Send an identity document and your credentials as an agent to the following email:";
    var txt18 = "In the email include your name and password:";
    var txt19 = "Copy key";
    var txt20 = "Verify identity";
    var txt21 = "Send an identity document to the email";
    var txt22 = "Send an identity document and proof of your activity to the following email:";
    var txt23 = "Verify FIFA license";
    var txt24 = "Send proof of your FIFA license and/or license to represent minors to the following email:";
    var txt25 = "EDIT PROFILE";
    var txt26 = "PHOTO";
    var txt27 = "Verified";
    var txt28 = "Verify profile";
    var txt29 = "Availability unspecified";
    var txt30 = "Available on: ";
    var txt31 = "Unspecified";
    var txt32 = "Change";
    var txt33 = "BECOME A PREMIUM USER!";
    var txt34 = "CANCEL SUBSCRIPTION";
    var txt35 = "Weight:";
    var txt36 = "Height:";
    var txt37 = "Side:";
    var txt38 = "Type:";
    var txt39 = "Followers: ";
    var txt40 = "MESSAGES";
    var txt41 = "PROPOSALS RECEIVED";
    var txt42 = "Agent: ";
    var txt43 = "Agent free";
    var txt44 = "You have a request from the agent ";
    var txt45 = "Accept";
    var txt46 = "Reject";
    var txt47 = "Date of birth:";
    var txt48 = "Age:";
    var txt49 = "Nationality:";
    var txt50 = "Current team:";
    var txt51 = "REMOVE";
    var txt52 = "No team";
    var txt53 = "Currículum";
    var txt54 = "ADD TEAM";
    var txt55 = "Team";
    var txt56 = "Position";
    var txt57 = "Start date";
    var txt58 = "Is this your current team?";
    var txt59 = "No";
    var txt60 = "Yes";
    var txt61 = "End date";
    var txt62 = "ADD";
    var txt63 = "Start:";
    var txt64 = "Exit:";
    var txt65 = "No equipment.";
    var txt66 = "Videos";
    var txt67 = "ADD VIDEO";
    var txt68 = "FIFA Verified";
    var txt69 = "Verify FIFA";
    var txt70 = "Verified -16";
    var txt71 = "Verify -16";
    var txt72 = "CREATE OFFER";
    var txt73 = "CREATE EVENT";
    var txt74 = "PROPOSALS MADE";
    var txt75 = "PLAYERS THAT YOU FOLLOW AND ARE AVAILABLE";
    var txt76 = "SEE ALL";
    var txt77 = "You still have no players still available";
    var txt78 = "Founding date:";
    var txt79 = "Country:";
    var txt80 = "Representative";
    var txt81 = "No team/agency";
    var txt82 = "ADD TEAM/AGENCY";
    var txt83 = "Team/Agency:";
    var txt84 = "Position:";
    var txt85 = "Is this your current team/agency?";
    var txt86 = "There are no teams/agencies.";
    var txt87 = "No videos yet.";
    var txt88 = "PLAYERS REPRESENTED";
    var txt89 = "SEE ALL";
    var txt90 = "No players represented yet.";
    var txt91 = "Players you are following";

    function changeLang(){
        var x = localStorage.getItem('idiom');
 
        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "Subir fotografía de perfil";
            txt2 = "Recortar";
            txt3 = "SUBIR";
            txt4 = "CANCELAR";
            txt5 = "Cancelar suscripción";
            txt6 = "¿Confirma cancelar su suscripción?";
            txt7 = "SÍ, CONFIRMO";
            txt8 = "Cambiar tu disponibilidad";
            txt9 = "No estoy en ningún club y estoy disponible inmediatamente";
            txt10 = "Estoy en un club y estoy disponible inmediatamente sin indemnización (open contract)";
            txt11 = "Estoy en un club y estoy disponible con indemnización (buy out)";
            txt12 = "Estoy en un club y estoy disponible bajo condiciones";
            txt13 = "Estoy en un club y estoy abierto a nuevas ofertas";
            txt14 = "FECHA DE DISPONIBILIDAD:";
            txt15 = "ACEPTAR";
            txt16 = "Verificar identidad de agente";
            txt17 = "Por seguridad de nuestros usuarios y jóvenes jugadores, es requerido que verifique su identidad. Envie un documento de identidad y sus credenciales como agente al siguiente email:";
            txt18 = "En el correo incluya su nombre y su clave:";
            txt19 = "Copiar clave";
            txt20 = "Verificar identidad";
            txt21 = "Envía un documento de identidad al email";
            txt22 = "Envía un documento de identidad y papelería probatoria de tu actividad al siguiente email:";
            txt23 = "Verificar licencia FIFA";
            txt24 = "Envía la papelería probatoria de tu licencia FIFA y/o licencia para representar menores al siguiente email:";
            txt25 = "EDITAR PERFIL";
            txt26 = "FOTO";
            txt27 = "Verificado";
            txt28 = "Verificar perfil";
            txt29 = "Disponibilidad sin especificar";
            txt30 = "Disponible el: ";
            txt31 = "Sin especificar";
            txt32 = "Cambiar";
            txt33 = "¡CONVIÉRTETE EN USUARIO PREMIUM!";
            txt34 = "CANCELAR SUSCRIPCIÓN";
            txt35 = "Peso:";
            txt36 = "Altura:";
            txt37 = "Lateralidad:";
            txt38 = "Rama:";
            txt39 = "Seguidores: ";
            txt40 = "MENSAJES";
            txt41 = "PROPUESTAS RECIBIDAS";
            txt42 = "Agente: ";
            txt43 = "Libre de agente";
            txt44 = "Tienes una solicitud del agente ";
            txt45 = "Aceptar";
            txt46 = "Rechazar";
            txt47 = "Fecha de nacimiento:";
            txt48 = "Edad:";
            txt49 = "Nacionalidad:";
            txt50 = "Equipo actual:";
            txt51 = "ELIMINAR";
            txt52 = "Sin equipo";
            txt53 = "Currículum";
            txt54 = "AÑADIR EQUIPO";
            txt55 = "Equipo";
            txt56 = "Posición";
            txt57 = "Fecha de inicio";
            txt58 = "¿Es tu equipo actual?";
            txt59 = "No";
            txt60 = "Sí";
            txt61 = "Fecha de fin";
            txt62 = "AÑADIR";
            txt63 = "Inicio:";
            txt64 = "Salida:";
            txt65 = "No hay equipos.";
            txt66 = "Videos";
            txt67 = "AÑADIR VIDEO";
            txt68 = "Verificado FIFA";
            txt69 = "Verificar FIFA";
            txt70 = "Verificado -16";
            txt71 = "Verificar -16";
            txt72 = "CREAR OFERTA";
            txt73 = "CREAR EVENTO";
            txt74 = "PROPUESTAS HECHAS";
            txt75 = "JUGADORES QUE SIGUES DISPONIBLES";
            txt76 = "VER TODOS";
            txt77 = "Aún no tiene jugadores seguidos disponibles";
            txt78 = "Fecha de fundación:";
            txt79 = "País:";
            txt80 = "Representante";
            txt81 = "Sin equipo/agencia";
            txt82 = "AÑADIR EQUIPO/AGENCIA";
            txt83 = "Equipo/Agencia:";
            txt84 = "Puesto:";
            txt85 = "¿Es tu equipo/agencia actual?";
            txt86 = "No hay equipos/agencias.";
            txt87 = "No hay videos aún.";
            txt88 = "JUGADORES REPRESENTADOS";
            txt89 = "VER TODOS";
            txt90 = "Aún no tiene jugadores representados";
            txt91 = "Jugadores que sigues";
        }
        else{
            console.log("Lang: English");
        }
    }
 
    changeLang();

    //SET VIDEOS
    async function setVideos(){
        var a = '<iframe src=';
        var b = 'title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';

        var x = videolink;
        var y = x.substring(0, 23);
        var z = x.replace('/watch?v=', '/embed/')
        console.log(y);

        if(y == "https://www.youtube.com"){
            if(activeuser.profile == "Player"){
                await addVideoplayer(activeuser.key_id, a + '"' + z + '"' + " " + b);
                closeVbox('add_video_box');
            }
            else if (activeuser.profile == "Coach"){
                await addVideocoach(activeuser.key_id, a + '"' + z + '"' + " " + b);
                closeVbox('add_video_box');
            }
        }else{
            alert("Video must be from YouTube.");
        }
    } 

    //SET TEAM CURRICULUM
    async function setTeam(){
        var team = {
            team_name: document.getElementById('team_name').value,
            team_position: document.getElementById('team_position').value,
            team_init: document.getElementById('team_init').value,
            team_end: document.getElementById('team_end').value,
        }
        console.log(team);
        if(activeuser.profile == "Player"){
            await addTeamPl(activeuser.key_id, team);
            clearInputs();
            closeVbox('add_team_box');
        }
        else if(activeuser.profile == "Agent"){
            await addTeamAg(activeuser.key_id, team);
            clearInputs();
            closeVbox('add_team_box');
        }
        else if(activeuser.profile == "Coach"){
            await addTeamCo(activeuser.key_id, team);
            clearInputs();
            closeVbox('add_team_box');
        }  
    } 

    //DELETE TEAM FROM CURRICULUM
    function deleteTeam(x){
        if(activeuser.profile == "Player"){
            quitTeamPl(activeuser.key_id, x);
        }
        else if(activeuser.profile == "Agent"){
            quitTeamAg(activeuser.key_id, x);
        }
        else if(activeuser.profile == "Coach"){
            quitTeamCo(activeuser.key_id, x);
        }  
    } 

    //
    function setCurrentTeam(){
        var a = document.getElementById("current_team").value;
        var b = document.getElementById("team_subfield1");

        if (a == '0'){
            b.style.display = "block";
        }else{
            b.style.display = "none";
        }
    } 

    //
    function clearInputs(){
        var a = document.getElementById('team_name');
        var b = document.getElementById('team_position');
        var c = document.getElementById('team_init');
        var d = document.getElementById('team_end');
        var e = document.getElementById('video_link');

        a.value = '';
        b.value = '';
        c.value = '';
        d.value = '';
        e.value = '';
    }


    //
    async function delSub(){
        try {
            const x = await axios.delete('https://conexserver.fly.dev/cancel?id=' + premium);
            //const x = await axios.delete('http://localhost:3001/cancel?id=' + premium); 
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }

    //USE EFFECT
    useEffect(() => {
        getData();
        console.log(premium);
        console.log(premiumsub);
        getAge(activeuser.birthday);
    },[activeuser, ready]);

    //
    async function getData(){
        if(activeuser.profile == "Player"){
            await getPlayerAgent(activeuser.agent);
            await getOffersPlayer();
            await getMessages();
            await getFollowerAgents(activeuser.key_id);
            await getFollowerCoaches(activeuser.key_id);
            await getFollowerClubs(activeuser.key_id);
            fillOffers();
            setReady(true);
        }
        else{
            await getAgentPlayers(activeuser.key_id , "1");
            await getOffersAgent();
            await getMessages();
            await getFollowingPlayers(activeuser.key_id);
            fillOffers();
            setReady(true);
        }   
    }


    //
    async function fillOffers(){
        setListoffers(alloffers);
    }

    //
    function openVbox(a){
        document.getElementById(a).style.display = "block";
    }
 
     //
    function closeVbox(a){
         document.getElementById(a).style.display = "none";
    }

    //
    const navigate = useNavigate();
    
    function goPage(a){
        navigate(a)
    }
    
    function goProfile(){
        if(activeuser.profile == "Player"){
            navigate('/Profile')
        }
        else if(activeuser.profile == "Agent"){
            navigate('/ProfileAgent')
        }
        else if(activeuser.profile == "Coach"){
            navigate('/ProfileCoach')
        }
        else if(activeuser.profile == "Club"){
            navigate('/ProfileClub')
        }
    }


    function goChat(x){
        navigate(`/Chat_offers/${ x }`);
    }

    function goProposals(x){
        navigate(`/Proposals/${ x }`);
    }

    function goPostOffer(){
        navigate('/OpenOffer')
    }

    function goPostEvent(){
        navigate('/Event')
    }

    function goOffers(){
        navigate('/Offers')
    }

    function goEvents(){
        navigate('/Events')
    }

    //
    var cropper;

    function loadEditor(){
        const imag = document.getElementById('imagetoup');
        cropper = new Cropper(imag, {
            aspectRatio: 1 / 1,
            crop(event) {
                console.log(event.detail.x);
                console.log(event.detail.y);
                console.log(event.detail.width);
                console.log(event.detail.height);
                console.log(event.detail.rotate);
                console.log(event.detail.scaleX);
                console.log(event.detail.scaleY);
            },
        });

        document.getElementById('edit_btn').style.display = "none";
        return cropper;
    }

    //
    function editorData(){
        var cropped = cropper.getCroppedCanvas().toDataURL("image/png");
        console.log(cropped);
    }
    
    
    //
    function selectImage( event ){
        console.log(image);
        const imageFiles = event.target.files;
        setImagefull(imageFiles[0]);
        const imageSrc = URL.createObjectURL(imageFiles[0]);
        setImage(imageSrc);
        
        console.log(image);
    }

    //
    function
    checkIm(){
        console.log(image);
    }

    //
    function uploadImage(){
        //const file = document.getElementById("imagetoup").src;

        if(cropper != null){
            return new Promise ((resolve, reject) =>{
                var x;
                const encodeimg = cropper.getCroppedCanvas({width: 300, height: 300,}).toDataURL("image/jpg");
                    resolve(
                        x = encodeimg
                );   
            });   
        }
        else{
            return new Promise ((resolve, reject) =>{
                const file = imageFull;
    
                const reader = new FileReader();
                reader.readAsDataURL(file);
    
            
                reader.onload = function(event) {
                    const imgElement = document.createElement("img");
                    imgElement.src = event.target.result;
                        
                    var x;
    
                    imgElement.onload = function (e) {
                        const canvas = document.createElement("canvas");
                        const max_width = 300;
                        const scaleSize = max_width / imgElement.width;
                        canvas.width = max_width;
                        canvas.height = imgElement.height * scaleSize;
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
                        const encodeimg = ctx.canvas.toDataURL(e.target, "image/jpg");
                        resolve(
                             x = encodeimg
                        );
                    };
                }
            });   
        }
    }

    //
    function uploadCropped(){
        return new Promise ((resolve, reject) =>{
            var x;
            const encodeimg = cropper.getCroppedCanvas({width: 300, height: 300,}).toDataURL("image/png");
                resolve(
                    x = encodeimg
            );   
        });   
    }

    //
    function uploadAvatar(){
        if(cropper != null){
            uploadCropped();
        }else{
            uploadImage();
        }
    }

    //
    async function subir(){
        if (image != null){
            var im = activeuser.key_id + "/" + "improfile.jpg";
            //const r = await uploadImage();
            const r = await uploadImage();
            await uploadPhotoMin(im, r);
            closePop('popup1'); 
            if(cropper != null){
                cropper.destroy();
                console.log("Se subio la imagen recortada");
            }else{
                console.log("Se subio la imagen normal");
            }
           
        }else{
            alert("Elige una imagen de tus archivos");
        }

    }

    //
    async function setStatus(){
        var x = document.getElementById('status_av').value;
        var y = document.getElementById('status_date').value;
        await saveStatus(activeuser.key_id, x, y);
        document.getElementById('popup3').style.display = "none";
    }

    //
    function openPop(p){
        document.getElementById(p).style.display = "flex";
    }

    //
    function clearIminput(){
        var x = document.getElementById('filephoto');
        x.value = '';
    }

    //
    function closePop(p){
        document.getElementById(p).style.display = "none";
        clearIminput();
        setImage(null);
        if(cropper != null){
            cropper.destroy();
            console.log("Se quitó la imagen recortada");
        }else{
            console.log("Sin imagen para recortar");
        }
    }


    //
    function copyId(){
        navigator.clipboard.writeText(activeuser.key_id);
    }


    //
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var aged = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            aged--;
        }
        setAge(aged);
    }

    //ELIMINAR
    function testDate(){
        var x = new Date();
        console.log(x.toDateString());
    }
    

    return(
        <>
        <div className="popup" id="popup1">
            <div className="popup_box2">
                <p className="popup_t2">{txt1}</p>
                {/*<input type="file"  id="filephoto" onChange={e => setImage(e.target.files[0])}/>*/}
                <input type="file"  id="filephoto" onChange={selectImage}/>
                <div className="preview_img">
                {
                    image == null
                    ?
                    null
                    :
                    <img id="imagetoup" src={image} alt="Preview"/>
                }
                </div>
                <div>
                    {
                        image == null
                        ?
                        null
                        :
                        <button className="btn_min" id="edit_btn" onClick={loadEditor}>{txt2}</button>
                    }
                </div>
                <div className="pop_buttons">
                    <button className="btn" onClick={subir}>{txt3}</button>
                    <button className="btn_cancel" onClick={()=> closePop('popup1')}>{txt4}</button>
                </div>
            </div>
        </div>

        <div className="popup" id="popup2">
            <div className="popup_box2">
                <p className="popup_t2">{txt5}</p>
               <p>{txt6}</p>
                <div className="pop_buttons">
                    <button className="btn" onClick={delSub}>{txt7}</button>
                    <button className="btn_cancel" onClick={()=> closePop('popup2')}>{txt4}</button>
                </div>
            </div>
        </div>

        <div className="popup" id="popup3">
            <div className="popup_box2">
                <p className="popup_t2">{txt8}</p>
                <select name="status_av" id="status_av" className="gral_input">
                    <option value="1">{txt9}</option>
                    <option value="2">{txt10}</option>
                    <option value="3">{txt11}</option>
                    <option value="4">{txt12}</option>
                    <option value="5">{txt13}</option>
                </select>
                <div className="pop_input">
                    <label htmlFor="status_date">{txt14}</label>
                    <input className="sec_input" type="date" id="status_date" name="date"/>
                </div>
                <div className="pop_buttons">
                    <button className="btn" onClick={setStatus}>{txt15}</button>
                    <button className="btn_cancel" onClick={()=> closePop('popup3')}>{txt4}</button>
                </div>
            </div>
        </div>

        {
            activeuser.profile == "Agent" && activeuser.verificated != "1"
            ?
            <div className="popup" id="popup_blocker">
                <div className="popup_box2">
                    <p className="popup_t2">{txt16}</p>
                    <p>{txt17}<br/><strong>verificacion@conexsport.com</strong></p>
                    <p>{txt18}<br/><strong>{activeuser.key_id}</strong></p>
                    <button className="btn_min" onClick={copyId}>{txt19}</button>
                    <div className="pop_buttons">
                        <button className="btn" onClick={()=> closePop('popup_blocker')}>{txt15}</button>
                    </div>
                </div>
            </div>
            :
            null
        }

        <div className="popup" id="popup4">
            <div className="popup_box2">
                <p className="popup_t2">{txt20}</p>
                <p>{txt21}<br/><strong>verificacion@conexsport.com</strong></p>
                <p>{txt18}<br/><strong>{activeuser.key_id}</strong></p>
                <button className="btn_min" onClick={copyId}>{txt19}</button>
                <div className="pop_buttons">
                    <button className="btn" onClick={()=> closePop('popup4')}>{txt15}</button>
                </div>
            </div>
        </div>

        <div className="popup" id="popup5">
            <div className="popup_box2">
                <p className="popup_t2">{txt20}</p>
                <p>{txt22}<br/><strong>verificacion@conexsport.com</strong></p>
                <p>{txt18}<br/><strong>{activeuser.key_id}</strong></p>
                <button className="btn_min" onClick={copyId}>{txt19}</button>
                <div className="pop_buttons">
                    <button className="btn" onClick={()=> closePop('popup5')}>{txt15}</button>
                </div>
            </div>
        </div>

        <div className="popup" id="popup6">
            <div className="popup_box2">
                <p className="popup_t2">{txt23}</p>
                <p>{txt24}<br/><strong>verificacion@conexsport.com</strong></p>
                <p>{txt18}<br/><strong>{activeuser.key_id}</strong></p>
                <button className="btn_min" onClick={copyId}>{txt19}</button>
                <div className="pop_buttons">
                    <button className="btn" onClick={()=> closePop('popup6')}>{txt15}</button>
                </div>
            </div>
        </div>

        <Top/>
        
        <div className="main">
           
            {
            
            activeuser.profile == "Player"
            ?
            //Si el perfil es jugador
            <div className="profile_box">
                <div className="profile_top">
                    <button className="btn_min" onClick={goProfile}>{txt25}</button>
                    <div className="profile_photo">
                        <div className="profile_photo_im">
                            {
                                activeuser.photoURL
                                ?
                                <img className="profile_pic" src={activeuser.photoURL} alt="Foto de perfil"/>
                                :
                                null
                            }
                        </div>
                        <div className="pic_btn">
                            <button className="btn_min" onClick={() => openPop('popup1')}>{txt26}</button>
                        </div>
                    </div>
                    <div className="profile_data">
                        <h1>{activeuser.name + ' ' + activeuser.lastname}</h1>
                        <p>{activeuser.position ? activeuser.position : '--'} | {activeuser.nationality ? activeuser.nationality : '--'}</p>
                        <div className="verificated_line">
                            {
                                activeuser.verificated == "1"
                                ?
                                <p className="verificated"><span>&#10003; </span>{txt27}</p>
                                :
                                <button className="btn_min" onClick={() => openPop('popup4')}>{txt28}</button>
                            }
                        </div>
                        <div className="profile_available">
                            {
                                activeuser.status 
                                ? 
                                <div className="profile_available_stat">
                                    {
                                        activeuser.status == "1"
                                        ?
                                        <p><strong>{txt9}</strong></p>
                                        :
                                        null
                                    }  
                                    {
                                        activeuser.status == "2"
                                        ?
                                        <p><strong>{txt10}</strong></p>
                                        :
                                        null
                                    } 
                                    {
                                        activeuser.status == "3"
                                        ?
                                        <p><strong>{txt11}</strong></p>
                                        :
                                        null
                                    }
                                    {
                                        activeuser.status == "4"
                                        ?
                                        <p><strong>{txt12}</strong></p>
                                        :
                                        null
                                    }
                                    {
                                        activeuser.status == "5"
                                        ?
                                        <p><strong>{txt13}</strong></p>
                                        :
                                        null
                                    }                   
                                </div>
                                : 
                                <p className="profile_available_notset"><strong>{txt29}</strong></p>
                            }
                            
                            <p className="profile_available_date">{txt30} { activeuser.status_date ? activeuser.status_date : txt31}</p>
                            
                            <button className="btn_min" onClick={() => openPop('popup3')}>{txt32}</button>
                        </div>
                        <div className="top_buttons">
                            {
                               (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                ?
                                <div className="top_btn_set">
                                    <button className="btn_cancel_min" onClick={() => openPop('popup2')}>{txt34}</button>
                                </div> 
                                :
                                <div className="top_btn_set">
                                    <button className="btn_min_gold" onClick={buySub}>{txt33}</button>
                                </div>
                            }
                            {
                                (premium != "" || premium != undefined) && (premiumsub === 'canceled' || premiumsub === 'incomplete' || premiumsub === 'incomplete_expired' || premiumsub === 'past_due' ||  premiumsub === 'unpaid')
                                ?
                                <p className="error_sub">An error occurred with the payment of your monthly subscription.</p>
                                :
                                null
                            }   
                        </div>
                        <div className="profile_nums">
                            <div className="profile_num">
                                <p className="profile_num_t">{txt35}</p>
                                <p className="profile_num_d">{activeuser.weight ? activeuser.weight + " " + "kg" : '--'}</p>
                            </div>
                            <div className="profile_num">
                                <p className="profile_num_t">{txt36}</p>    
                                <p className="profile_num_d">{activeuser.height ? activeuser.height + " " + "m" : '--'}</p>
                            </div>
                            <div className="profile_num">
                                <p className="profile_num_t">{txt37}</p>
                                <p className="profile_num_d">{activeuser.dominant_leg ? activeuser.dominant_leg : '--'}</p>
                            </div>
                            <div className="profile_num">
                                <p className="profile_num_t">{txt38}</p>
                                <p className="profile_num_d">{activeuser.gender ? activeuser.gender : '--'}</p>
                            </div>
                        </div>
                        <button className="btn_min btn_follow" onClick={() => goPage('/Followers')}><strong>{txt39} </strong>{flagents.length + flcoaches.length}</button>
                    </div>
                    <div className="clear"></div>
                </div>
        

                <div className="profile_timeline">
                    <div className="subtop_buttons">
                        <button className="btn_min" onClick={() => goPage('/Messages')}>{txt40}</button>
                        <button className="btn_min" onClick={() => goPage('/OffersList')}>{txt41 + ' ' + listoffers.length}</button>
                    </div>
                    <div className="profile_bio">
                        <p>{activeuser.bio ? activeuser.bio : '--'}</p>
                        <div className="profile_bio2">
                                {
                                    playeragent.length == 0
                                    ?
                                    <p><strong>{txt42}</strong>{txt43}</p>
                                    :
                                    <div>
                                        {
                                            activeuser.validated_agent != "0"
                                            ?
                                            <p><strong>{txt42}</strong><Link className='textlink' to={`/Agent/${ playeragent[0].key_id}`}>{playeragent[0].name + ' ' + playeragent[0].lastname}</Link></p>
                                            :
                                            <div>
                                                <p>{txt44}<Link className='textlink' to={`/Agent/${ playeragent[0].key_id}`}>{playeragent[0].name + ' ' + playeragent[0].lastname}</Link></p>
                                                <div>
                                                    <button className="btn_min" onClick={() => accAg(activeuser.key_id)}>{txt45}</button>
                                                    <button className="btn_cancel_min" onClick={() => delAg(activeuser.key_id)}>{txt46}</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                        </div>
                    </div>
                    
                    <div className="profile_stats">
                        <div className="stat">
                            <p className="stat_t">{txt47}</p>
                            <p className="stat_d">{activeuser.birthday ? activeuser.birthday : '--'}</p>
                        </div>
                        <div className="stat">
                            <p className="stat_t">{txt48}</p>
                            <p className="stat_d">{ age === 0 ? null : age }</p>
                        </div>
                        <div className="stat">
                            {
                                activeuser.teams && activeuser.teams.length > 0
                                ?
                                activeuser.teams.filter(x=> x.team_end === '').map((item, index) => (
                                    <div key={index}>
                                        <p className="stat_t">{txt50}</p>
                                        <p className="stat_d">{item.team_name}</p>
                                    </div>
                                ))
                                :
                                <p className="stat_t">{txt52}</p>
                            }    
                        </div>
                    </div>

                    <div className="profile_boxes">
                        <h2>{txt53}</h2>

                        <button className="btn" onClick={() => openVbox('add_team_box')}>{txt54}</button>
                        <div id="add_team_box">
                            <div className="team_field">
                                <label htmlFor="team_name">{txt55}</label>
                                <input type="text" id="team_name" name="team_name"/>
                            </div>
                            <div className="team_field">
                                <label htmlFor="team_position">{txt56}</label>
                                <input type="text" id="team_position" name="team_position"/>
                            </div>
                            <div className="team_field">
                                <label htmlFor="team_init">{txt57}</label>
                                <input type="date" id="team_init" name="team_init"/>
                            </div>
                            <div className="team_field">
                                <label htmlFor="current_team">{txt58}</label>
                                <select id="current_team" name="current_team" onChange={setCurrentTeam}>
                                    <option value="0">{txt59}</option>
                                    <option value="1">{txt60}</option>
                                </select>
                                <div className="team_subfield" id="team_subfield1">
                                    <label htmlFor="team_end">{txt61}</label>
                                    <input type="date" id="team_end" name="team_end" defaultValue="current"/>
                                </div>
                            </div>
                            <div className="clear"></div>
                            <div>
                                <button className="btn_cancel" onClick={() => closeVbox('add_team_box')}>{txt4}</button>
                                <button className="btn" onClick={setTeam}>{txt62}</button>
                            </div>
                        </div>
                        <div className="teams_list">
                            {
                                activeuser.teams && activeuser.teams.length > 0
                                ?
                                activeuser.teams.slice()
                                .sort((a, b) => {
                                    if (a.team_end === "" && b.team_end !== "") {
                                        return -1; // Colocar a al principio
                                    } else if (a.team_end !== "" && b.team_end === "") {
                                        return 1; // Colocar b al principio
                                    } else {
                                        return new Date(b.team_end) - new Date(a.team_end); // Ordenar por fechas
                                    }
                                }).map((item, index) => (
                                    <div key={index} className="team_element">
                                        <p className="list_name">{item.team_name}</p>
                                        <p className="list_pos">{item.team_position}</p>
                                        <p className="list_pos">{txt63 + ' ' + item.team_init} | {txt64 + ' ' + item.team_end}</p>
                                        <button className="btn_cancel_min" onClick={() => deleteTeam(item)}>{txt51}</button>
                                    </div>
                                ))
                                :
                                <div>{txt65}</div>
                            }
                        </div>
                        
                    </div>

                    <div className="profile_boxes">
                        <h2>{txt66}</h2>
                        <button className="btn" onClick={() => openVbox('add_video_box')}>{txt67}</button>
                        <div id="add_video_box">
                            <input
                                id="video_link"
                                type="text"
                                onChange={e => setVideolink(e.target.value)}
                                placeholder="Copy link from YouTube"
                            />
                           
                            <button className="btn_cancel" onClick={() => closeVbox('add_video_box')}>{txt4}</button>
                            <button className="btn" onClick={setVideos}>{txt62}</button>
                        </div>
                        <div className="videos_list">
                            {
                            activeuser.videos && activeuser.videos.length > 0
                            ?
                            activeuser.videos.map((item, index) => (
                                <div key={index} className="video_element">
                                    <div dangerouslySetInnerHTML={{__html: item }} />
                                    <button className="btn_cancel_min" onClick={() => quitVideoplayer(activeuser.key_id, item)}>{txt51}</button>
                                </div>
                            ))
                            :
                            <div>{txt87}</div>
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>

                <div className="clear"></div>

            </div>

            :
            //Si el perfil es agente
            <div className="profile_box">
                    
                    <div className="profile_top">
                        <button className="btn_min" onClick={goProfile}>{txt25}</button>
                        <div className="profile_photo">
                            <div className="profile_photo_im">
                                {
                                    activeuser.photoURL
                                    ?
                                    <img className="profile_pic" src={activeuser.photoURL} alt="Foto de perfil"/>
                                    :
                                    null
                                }
                            </div>
                            <div className="pic_btn">
                                <button className="btn_min" onClick={() => openPop('popup1')}>{txt26}</button>
                            </div>
                        </div>
                        <div className="profile_data">
                            {
                                activeuser.profile == "Club"
                                ?
                                <div>
                                    <h1>{activeuser.name}</h1>
                                    <p>{activeuser.profile ? activeuser.profile : '--'} | {activeuser.league ? activeuser.league: '--'}</p>
                                </div>
                                :
                                <div>
                                    <h1>{activeuser.name + ' ' + activeuser.lastname}</h1>
                                    <p>{activeuser.profile ? activeuser.profile : '--'}</p>
                                </div>
                            }
                            
                            <div className="verificated_line">
                                {
                                    activeuser.verificated == "1"
                                    ?
                                    <p className="verificated"><span>&#10003; </span>{txt27}</p>
                                    :
                                    <button className="btn_min" onClick={() => openPop('popup5')}>{txt28}</button>
                                }
                            </div>

                            <div className="verificated_line">
                                {
                                    activeuser.profile == "Agent"
                                    ?
                                    <div>
                                        <div className="verificated_line">
                                            {
                                                activeuser.verificated_fifa == "1"
                                                ?
                                                <p className="verificated2">{txt68}</p>
                                                :
                                                <button className="btn_min" onClick={() => openPop('popup6')}>{txt69}</button>
                                            }
                                        </div>
                                        <div className="verificated_line">
                                            {
                                                activeuser.verificated_six == "1"
                                                ?
                                                <p className="verificated2">{txt70}</p>
                                                :
                                                <button className="btn_min" onClick={() => openPop('popup6')}>{txt71}</button>
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            <div className="top_buttons">
                                {
                                (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                    ?
                                    <div className="top_btn_set">
                                        <button className="btn_cancel_min" onClick={() => openPop('popup2')}>{txt34}</button>
                                    </div> 
                                    :
                                    <div className="top_btn_set">
                                        <button className="btn_min_gold" onClick={buySub}>{txt33}</button>
                                    </div>
                                }
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'canceled' || premiumsub === 'incomplete' || premiumsub === 'incomplete_expired' || premiumsub === 'past_due' ||  premiumsub === 'unpaid')
                                    ?
                                    <p className="error_sub">An error occurred with the payment of your monthly subscription.</p>
                                    :
                                    null
                                }   
                            </div>

                            {
                                activeuser.profile == "Coach"
                                ?
                                <button className="btn_min btn_follow" onClick={() => goPage('/Following')}>{txt91}</button>
                                :
                                null
                            }  

                        </div>
                        <div className="clear"></div>
                    </div>
                    
                    
                    <div className="profile_timeline">
                        <div className="subtop_buttons">
                            <button className="btn_min" onClick={goPostOffer}>{txt72}</button>
                            <button className="btn_min" onClick={goPostEvent}>{txt73}</button>
                            <button className="btn_min" onClick={() => goPage('/Messages')}>{txt40}</button>
                            <button className="btn_min" onClick={() => goPage('/OffersList')}>{txt74 + ' ' + listoffers.length}</button>
                        </div>
                        <div className="profile_bio">
                            <p>{activeuser.bio ? activeuser.bio : '--'}</p>
                        </div>

                        {
                            activeuser.profile == "Agent" || activeuser.profile == "Club"
                            ?
                            <div className="profile_stats">
                                <div className="profile_tt">
                                    <h3>{txt75}</h3>
                                    <button className="btn_min" onClick={() => goPage('/Following')}>{txt76}</button>
                                </div>
                                {
                                    flplayers.length == 0
                                    ?
                                    <div>{txt77}</div>
                                    :
                                    flplayers.slice(0, 5).map((item, index) => (
                                        <Link key={index} className='list_item4' to={`/Player/${ item.id }`}>
                                            <div className="list_photo"> 
                                                {
                                                    item.photoURL
                                                    ?
                                                    <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="list_data">
                                                <p className="list_name">{item.name + " " + item.lastname}</p>
                                                <p className="list_pos">{item.position + " | " + item.nationality}</p>
                                            </div>
                                            <div className="clear"></div>
                                        </Link>
                                    ))
                                }
                                <div className="clear"></div>
                            </div>
                            :
                            null

                        }

                        {
                            activeuser.profile == "Club"
                            
                            ?

                            <div className="profile_stats">
                                <div className="stat">
                                    <p className="stat_t">{txt78}</p>
                                    <p className="stat_d">{activeuser.birthday ? activeuser.birthday : '--'}</p>
                                </div>
                                <div className="stat">
                                    <p className="stat_t">{txt79}</p>
                                    <p className="stat_d">{activeuser.nationality ? activeuser.nationality : '--'}</p>
                                </div>
                                <div className="stat">
                                    <p className="stat_t">{txt80}</p>
                                    <p className="stat_d">{activeuser.current_rep ? activeuser.current_rep : '--'}</p>
                                </div>
                            </div>

                            :
                            <div>

                                <div className="profile_stats">
                                    <div className="stat">
                                        <p className="stat_t">{txt47}</p>
                                        <p className="stat_d">{activeuser.birthday ? activeuser.birthday : '--'}</p>
                                    </div>
                                    <div className="stat">
                                        <p className="stat_t">{txt48}</p>
                                        <p className="stat_d">{ age === 0 ? null : age }</p>
                                    </div>
                                    <div className="stat">
                                        <p className="stat_t">{txt49}</p>
                                        <p className="stat_d">{activeuser.nationality ? activeuser.nationality : '--'}</p>
                                    </div>
                                    <div className="stat">
                                        {
                                            activeuser.teams && activeuser.teams.length > 0
                                            ?
                                            activeuser.teams.filter(x=> x.team_end === '').map((item, index) => (
                                                <div key={index}>
                                                    <p className="stat_t">{txt50}</p>
                                                    <p className="stat_d">{item.team_name}</p>
                                                </div>
                                            ))
                                            :
                                            <p className="stat_t">{txt81}</p>
                                        }    
                                    </div>
                                </div>

                                <div className="profile_boxes">
                                    <h2>{txt53}</h2>

                                    <button className="btn" onClick={() => openVbox('add_team_box')}>{txt82}</button>
                                    <div id="add_team_box">
                                        <div className="team_field">
                                            <label htmlFor="team_name">{txt83}</label>
                                            <input type="text" id="team_name" name="team_name"/>
                                        </div>
                                        <div className="team_field">
                                            <label htmlFor="team_position">{txt84}</label>
                                            <input type="text" id="team_position" name="team_position"/>
                                        </div>
                                        <div className="team_field">
                                            <label htmlFor="team_init">{txt57}</label>
                                            <input type="date" id="team_init" name="team_init"/>
                                        </div>
                                        <div className="team_field">
                                        <label htmlFor="current_team">{txt85}</label>
                                            <select id="current_team" name="current_team" onChange={setCurrentTeam}>
                                                <option value="0">{txt59}</option>
                                                <option value="1">{txt60}</option>
                                            </select>
                                            <div className="team_subfield" id="team_subfield1">
                                                <label htmlFor="team_end">{txt61}</label>
                                                <input type="date" id="team_end" name="team_end" defaultValue="current"/>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                        <div>
                                            <button className="btn_cancel" onClick={() => closeVbox('add_team_box')}>{txt4}</button>
                                            <button className="btn" onClick={setTeam}>{txt62}</button>
                                        </div>
                                    </div>
                                    <div className="teams_list">
                                        {
                                            activeuser.teams && activeuser.teams.length > 0
                                            ?
                                            activeuser.teams.slice()
                                            .sort((a, b) => {
                                                if (a.team_end === "" && b.team_end !== "") {
                                                    return -1; // Colocar a al principio
                                                } else if (a.team_end !== "" && b.team_end === "") {
                                                    return 1; // Colocar b al principio
                                                } else {
                                                    return new Date(b.team_end) - new Date(a.team_end); // Ordenar por fechas
                                                }
                                            }).map((item, index) => (
                                                <div key={index} className="team_element">
                                                    <p className="list_name">{item.team_name}</p>
                                                    <p className="list_pos">{item.team_position}</p>
                                                    <p className="list_pos">{txt63 + ' ' + item.team_init} | {txt64 + ' ' + item.team_end}</p>
                                                    <button className="btn_cancel_min" onClick={() => deleteTeam(item)}>{txt51}</button>
                                                </div>
                                            ))
                                            :
                                            <div>{txt86}</div>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        }

                        {
                            activeuser.profile == "Club" || activeuser.profile == "Coach"
                            
                            ?

                            null

                            :

                            <div className="profile_stats">
                                <div className="stat">
                                    <div className="profile_tt">
                                        <h3>{txt88}</h3>
                                        <button className="btn_min" onClick={() => goPage('/Roster')}>{txt89}</button>
                                    </div>
                                    {
                                        agentplayers.length == 0
                                        ?
                                        <div>{txt90}</div>
                                        :
                                        <div className="profile_list">
                                            {agentplayers.slice(0, 10).map((item, index) => (
                                                <Link key={index} className='list_item5' to={`/Player/${ item.id }`}>
                                                    <div className="list_photo"> 
                                                        {
                                                            item.photoURL
                                                            ?
                                                            <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className="list_data3">
                                                        <p className="list_name">{item.name + " " + item.lastname}</p>
                                                        <p className="list_pos">{item.position + " | " + item.nationality}</p>
                                                    </div>
                                                    <div className="clear"></div>
                                                </Link>
                                            ))}
                                            <div className="clear"></div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {
                            activeuser.profile == "Coach"
                            ?
                            <div className="profile_boxes">
                                <h2>{txt66}</h2>
                                <button className="btn" onClick={() => openVbox('add_video_box')}>{txt67}</button>
                                <div id="add_video_box">
                                    <input
                                        id="video_link"
                                        type="text"
                                        onChange={e => setVideolink(e.target.value)}
                                        placeholder="Copy link from YouTube"
                                    />
                                
                                    <button className="btn_cancel" onClick={() => closeVbox('add_video_box')}>{txt4}</button>
                                    <button className="btn" onClick={setVideos}>{txt62}</button>
                                </div>
                                <div className="videos_list">
                                    {
                                    activeuser.videos && activeuser.videos.length > 0
                                    ?
                                    activeuser.videos.map((item, index) => (
                                        <div key={index} className="video_element">
                                            <div dangerouslySetInnerHTML={{__html: item }} />
                                            <button className="btn_cancel_min" onClick={() => quitVideocoach(activeuser.key_id, item)}>{txt51}</button>
                                        </div>
                                    ))
                                    :
                                    <div>{txt87}</div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>
                            :
                            null
                        }


                    </div>

                    <div className="clear"></div>
                    
                </div>
            }
            
        </div>
        </>

    );


}

export default User;