import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';

const Players = () => {

    //
    const { getPlayers, allplayers, searchListPlayers, nextPlayers, filterList, lastreg } = useContext(DataContext);

    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [reloader, setReloader] = useState(1);

    //TEXTS IDIOMS
    var txt1 = "PLAYERS";
    var txt2 = "NO RESULTS";
    var txt3 = "Search by name, position or nationality";
    var txt4 = "SEARCH";
    var txt5 = "Search by filters";
    var txt6 = "Type";
    var txt7 = "Men's";
    var txt8 = "Women's";
    var txt9 = "Position";
    var txt10 = "Goalkeeper";
    var txt11 = "Defender";
    var txt12 = "Midfielder";
    var txt13 = "Forward";
    var txt14 = "Availability";
    var txt15 = "I am not in any club and I am available immediately";
    var txt16 = "I am in a club and I am available immediately without compensation (open contract)";
    var txt17 = "I am in a club and I am available with severance pay (buy out)";
    var txt18 = "I am in a club and I am available under conditions";
    var txt19 = "I am in a club and I am open to new offers";
    var txt20 = "Verified";
    var txt21 = "Not verified";
    var txt22 = "Nationality";
    var txt23 = "Choose country";
    var txt24 = "Min. age";
    var txt25 = "Max. age";
    var txt26 = "Min. height";
    var txt27 = "Max. height";
    var txt28 = "FILTER";
    var txt29 = "LOAD MORE";
 
    function changeLang(){
        var x = localStorage.getItem('idiom');
 
        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "JUGADORES";
            txt2 = "SIN RESULTADOS";
            txt3 = "Busca por nombre, posición o nacionalidad";
            txt4 = "BUSCAR";
            txt5 = "Búsqueda por filtros";
            txt6 = "Rama";
            txt7 = "Varonil";
            txt8 = "Femenil";
            txt9 = "Posición";
            txt10 = "Portero";
            txt11 = "Defensa";
            txt12 = "Centrocampista";
            txt13 = "Delantero";
            txt14 = "Disponibilidad";
            txt15 = "No estoy en ningún club y estoy disponible inmediatamente";
            txt16 = "Estoy en un club y estoy disponible inmediatamente sin indemnización (open contract)";
            txt17 = "Estoy en un club y estoy disponible con indemnización (buy out)";
            txt18 = "Estoy en un club y estoy disponible bajo condiciones";
            txt19 = "Estoy en un club y estoy abierto a nuevas ofertas";
            txt20 = "Verificado";
            txt21 = "No verificado";
            txt22 = "Nacionalidad";
            txt23 = "Elige país";
            txt24 = "Edad mín.";
            txt25 = "Edad máx.";
            txt26 = "Altura mín.";
            txt27 = "Altura máx.";
            txt28 = "FILTRAR";
            txt29 = "CARGAR MÁS";
           
        }
        else{
            console.log("Lang: English");
        }
    }
 
    changeLang();


    //USE EFFECT
    useEffect(() => {
        fillArray();
    },[ready, reloader]);

    //
    async function fillArray(){
        try {
            await getPlayers();
            setList(allplayers);
            console.log(list);
            console.log(list.length);
            nextP();
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    //SEARCH ENGINE
    async function searching(){
        var a = document.getElementById("search_field").value.toLowerCase();
        var b = a.split(' ');
        var y = "players";
        await searchListPlayers(b, y);
        setList(allplayers);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);

    }

    //NEXT PAGE
    async function nextP(){
        if(lastreg == undefined){
            console.log("Init")
        }else{
            await nextPlayers('players');
            setList(allplayers);
        }  
    }


    //SEARCH BY FILTERS
    async function searchFilter(){
        var a = document.getElementById("gender_field").value;
        var b = document.getElementById("position_field").value;
        var c = document.getElementById("status_field").value;
        var d = document.getElementById("verified_field").value;
        var e = document.getElementById("nationality_field").value;
        var f = document.getElementById("min_age_field").value;
        var g = document.getElementById("max_age_field").value;
        var h = document.getElementById("min_h_field").value;
        var i = document.getElementById("max_h_field").value;

        await filterList(a, b, c, d, e, f, g, h, i, 'players');
        
        setList(allplayers);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);
    }

    //INPUT SLIDERS
    function changeNum(a, b){
        var x = document.getElementById(a).value;
        var y = document.getElementById(b);
       
        y.innerHTML = x.toString();
    }

    //RESET
    function resetPage(){
        window.location.reload();
    }

    return(
        <>
        <Top/>
        <div className="main">
            <div className="list_box">
                <h1>{txt1}</h1>
                <div className="search_box">
                    <div className="search_area">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt3}
                        />
                        <button className="btn_min" onClick={searching}>{txt4}</button>
                    </div>
                    <div className="advanced_box">
                        <p>{txt5}</p>
                        <div className="search_inp">
                            <label htmlFor="gender_field">{txt6}</label>
                            <select id="gender_field" name="gender_field">
                                <option value="Men's">{txt7}</option>
                                <option value="Women's">{txt8}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="position_field">{txt9}</label>
                            <select id="position_field" name="positon_field">
                            <option value="Goalkeeper/Portero">{txt10}</option>
                            <option value="Defense/Defensa">{txt11}</option>
                            <option value="Midfielder/Centrocampista">{txt12}</option>
                            <option value="Forward/Delantero">{txt13}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="status_field">{txt14}</label>
                            <select id="status_field">
                            <option value="1">{txt15}</option>
                            <option value="2">{txt16}</option>
                            <option value="3">{txt17}</option>
                            <option value="4">{txt18}</option>
                            <option value="5">{txt19}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="verified_field">{txt20}</label>
                            <select id="verified_field">
                                <option value="1">{txt20}</option>
                                <option value="">{txt21}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="nationality_field">{txt22}</label>
                            <select id="nationality_field">
                                <option value="">{txt23}</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Åland Islands">Åland Islands</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote D'ivoire">Cote D'ivoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Territories">French Southern Territories</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-bissau">Guinea-bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                <option value="Korea, Republic of">Korea, Republic of</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macao">Macao</option>
                                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                <option value="Moldova, Republic of">Moldova, Republic of</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russian Federation">Russian Federation</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Helena">Saint Helena</option>
                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-leste">Timor-leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Viet Nam">Viet Nam</option>
                                <option value="Virgin Islands, British">Virgin Islands, British</option>
                                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                <option value="Wallis and Futuna">Wallis and Futuna</option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="min_age_field">{txt24}</label>
                            <p id="min_age_output">16</p>
                            <div className="clear"></div>
                            <input type="range" id="min_age_field" name="min_age_field" min="16" max="50" step="1" defaultValue="16" onChange={()=>changeNum('min_age_field', 'min_age_output')}/>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="max_age_field">{txt25}</label>
                            <p id="max_age_output">18</p>
                            <div className="clear"></div>
                            <input type="range" id="max_age_field" name="max_age_field" min="16" max="50" step="1" defaultValue="18" onChange={()=>changeNum('max_age_field', 'max_age_output')}/>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="min_age_field">{txt26}</label>
                            <p id="min_h_output">1.40</p>
                            <div className="clear"></div>
                            <input type="range" id="min_h_field" name="min_h_field" min="1.40" max="2.20" step=".01" defaultValue="1.40" onChange={()=>changeNum('min_h_field', 'min_h_output')}/>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="min_age_field">{txt27}</label>
                            <p id="max_h_output">1.60</p>
                            <div className="clear"></div>
                            <input type="range" id="max_h_field" name="max_h_field"min="1.40" max="2.20" step=".01" defaultValue="1.60" onChange={()=>changeNum('max_h_field', 'max_h_output')}/>
                        </div>

                        <div className="clear"></div>
                        <button className="btn_min" onClick={searchFilter}>{txt28}</button>
                    </div>
                </div>
                {
                list.length == 0
                ?
                <div className="loading_msg">{txt2}</div>
                :
                list.map((item, index) => (
                    <Link to={`/Player/${ item.id }`}  key={item.id} className="list_item">
                        <div className="list_photo"> 
                            {
                                item.photoURL
                                ?
                                <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                :
                                null
                            }
                        </div>
                        <div className="list_data">
                            <p className="list_name">{item.name + " " + item.lastname}</p>
                            <p className="list_pos">{item.position + " | " + item.nationality}</p>
                            {
                                        item.status == "1"
                                        ?
                                        <p className="list_pos">{txt15}</p>
                                        :
                                        null
                                }  
                                {
                                        item.status == "2"
                                        ?
                                        <p className="list_pos">{txt16}</p>
                                        :
                                        null
                                } 
                                {
                                        item.status == "3"
                                        ?
                                        <p className="list_pos">{txt17}</p>
                                        :
                                        null
                                }
                                {
                                        item.status == "4"
                                        ?
                                        <p className="list_pos">{txt18}</p>
                                        :
                                        null
                                }
                                {
                                        item.status == "5"
                                        ?
                                        <p className="list_pos">{txt19}</p>
                                        :
                                        null
                                }                   
                        </div>
                        <div className="clear"></div>
                    </Link>
                ))}
                <div className="load_more_btn">
                    <button id="load_btn" className="btn_min" onClick={nextP}>{txt29}</button>
                    <button id="reset_btn" className="btn_min" onClick={resetPage}>RESET</button>
                </div>
               
            </div>
        </div>
        </>

    );


}

export default Players;