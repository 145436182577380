import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../images/logo.png';
import icon from '../images/ball.png';
import icon2 from '../images/agent.png';
import icon3 from '../images/coach.png';
import icon4 from '../images/club.png';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';


const Intro = () => {

    //TEXTS IDIOMS
    var txt1 = "CONEXSPORT IS YOUR WINDOW TO WORLD FOOTBALL";
    var txt2 = "REGISTER";
    var txt3 = "Through our platform you will be able to introduce yourself to the world";
    var txt4 = "HOW DOES IT WORK?";
    var txt5 = "BENEFITS";
    var txt6 = "HOW DOES IT WORK?";
    var txt7 = "Choose what type of account is yours.";
    var txt8 = "Player";
    var txt9 = "Agent";
    var txt10 = "Coach";
    var txt11 = "Club";
    var txt12 = "CONEXSPORT BENEFITS";
    var txt13 = "Players";
    var txt14 = "Visibility";
    var txt15 = "Opportunities";
    var txt16 = "Power of action";
    var txt17 = "Events and offers";
    var txt18 = "Agents";
    var txt19 = "Great administrative tool";
    var txt20 = "More visibility";
    var txt21 = "Expansion";
    var txt22 = "Trainers";
    var txt23 = "Visibility";
    var txt24 = "Find available players";
    var txt25 = "Connect with players";
    var txt26 = "Stay up to date";
    var txt27 = "Clubs";
    var txt28 = "Find players and coaches";
    var txt29 = "Centralize player data";
    var txt30 = "Event organization";
    var txt31 = "General community information";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "CONEXSPORT ES TU VENTANA AL FUTBOL MUNDIAL";
            txt2 = "REGISTRARSE";
            txt3 = "A través de nuestra plataforma podrás presentarte ante el mundo.";
            txt4 = "¿CÓMO FUNCIONA?";
            txt5 = "BENEFICIOS";
            txt6 = "¿CÓMO FUNCIONA?";
            txt7 = "Elige qué tipo de cuenta es la tuya";
            txt8 = "Jugador";
            txt9 = "Agente";
            txt10 = "Entrenador";
            txt11 = "Club";
            txt12 = "BENEFICIOS DE CONEXSPORT";
            txt13 = "Jugadores";
            txt14 = "Visibilidad";
            txt15 = "Oportunidades";
            txt16 = "Poder de acción";
            txt17 = "Eventos y ofertas";
            txt18 = "Agentes";
            txt19 = "Gran herramienta administrativa";
            txt20 = "Más visibilidad";
            txt21 = "Expansión";
            txt22 = "Entrenadores";
            txt23 = "Visibilidad";
            txt24 = "Encontrar jugadores disponibles";
            txt25 = "Conectar con agentes y clubes";
            txt26 = "Mantente actualizado";
            txt27 = "Clubes";
            txt28 = "Encontrar jugadores y entrenadores";
            txt29 = "Centralizar datos de jugadores";
            txt30 = "Organización de eventos";
            txt31 = "Información de la comunidad en general";
        }
        else{
            console.log("Lang: English");
        }
    }

    changeLang();
    
    //
    const navigate = useNavigate();

    //
    const { revUser, logOut } = useContext(DataContext);

    //
    function goRegister(){
        navigate('/Register');
    }

    //
    function scrollNav(a){
        var element = document.getElementById(a);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
    return (
        <div className="in_page">
            <Top/>
           
            <div className="in_text">
                <div className="in_box">
                    <h1>{txt1}</h1>
                    <button className="btn_big" onClick={goRegister}>{txt2}</button>
                    <p>{txt3}</p>
                    <div className="btns_intro">
                        <button className="btn_line" onClick={() => scrollNav('in_text2')}>{txt4}</button>
                        <button className="btn_line" onClick={() => scrollNav('in_text3')}>{txt5}</button>
                    </div>
                </div>
            </div>
            <div className="in_text2" id="in_text2">
                <h2>{txt6}</h2>
                <div>
                    <p className="in_sub">{txt7}</p>
                    <div className="in_icons">
                        <div className="in_icon">
                            <img src={icon} alt=""/>
                            <p>{txt8}</p>
                        </div>
                        <div className="in_icon">
                            <img src={icon2} alt=""/>
                            <p>{txt9}</p>
                         </div>
                        <div className="in_icon">
                            <img src={icon3} alt=""/>
                            <p>{txt10}</p>
                        </div>
                        <div className="in_icon">
                            <img src={icon4} alt=""/>
                            <p>{txt11}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="in_text3" id="in_text3">
                <h2>{txt12}</h2>
                <div className="in_list">
                    <p className="in_sub">{txt13}</p>
                    <div className="sub_txt">
                        <p>{txt14}</p>
                        <p>{txt15}</p>
                        <p>{txt16}</p>
                        <p>{txt17}</p>
                    </div>
                </div>
                <div className="in_list">
                    <p className="in_sub">{txt18}</p>
                    <div className="sub_txt">
                        <p>{txt19}</p>
                        <p>{txt20}</p>
                        <p>{txt21}</p>
                        <p>{txt17}</p>
                    </div>
                </div>
                <div className="in_list">
                    <p className="in_sub">{txt22}</p>
                    <div className="sub_txt">
                        <p>{txt23}</p>
                        <p>{txt24}</p>
                        <p>{txt25}</p>
                        <p>{txt26}</p>
                    </div>
                </div>
                <div className="in_list">
                    <p className="in_sub">{txt27}</p>
                    <div className="sub_txt">
                        <p>{txt28}</p>
                        <p>{txt29}</p>
                        <p>{txt30}</p>
                        <p>{txt31}</p>
                    </div>
                </div>
                <div className="clear"></div>
            </div>
        </div>

    );
}

export default Intro;