import React from 'react';


const Footer = () => {

  
    return (
        <div className="footer">
           <p>&copy; 2023 ConexSport. All rights reserved.</p>
           <p>Este sitio está en modo de desarrollo. Powered by <a href="https://renderplayz.com" target="_blank">Render Playz</a></p>
        </div>

    );
}

export default Footer;