import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Messages = () => {

    //
    const { activeuser, getMessages, allmessages, filtermessages } = useContext(DataContext);

    const [list, setList] = useState([]);
    const [list2, setList2] = useState([]);
    const [ready, setReady] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "MESSAGES";
    var txt2 = "Messages pending reply";
    var txt3 = "NO MESSAGES PENDING";
    var txt4 = "All messages";
    var txt5 = "Search by name of sender or receiver";
    var txt6 = "NO MESSAGES";
    var txt7 = "To: ";
    var txt8 = "From: ";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "MENSAJES";
            txt2 = "Mensajes pendientes de responder";
            txt3 = "SIN MENSAJES PENDIENTES";
            txt4 = "Todos los mensajes";
            txt5 = "Busca por nombre del emisor o del receptor";
            txt6 = "SIN MENSAJES";
            txt7 = "Para: ";
            txt8 = "De: ";
        }
        else{
            console.log("Lang: English");
        }
    }

    changeLang();


    //USE EFFECT
    useEffect(() => {
      fillArray();
    },[ready]);

    //
    async function fillArray(){
        try {
            await getMessages();
            setList(allmessages);
            setList2(filtermessages);
            console.log(list);
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    //SEARCH ENGINE
    function searching(){
        var list3 = allmessages;
        var a = document.getElementById("search_field").value.toLowerCase();
        var x = list3.filter(x => {
            return x.creator_name.toLowerCase().includes(a) || x.receptor_name.toLowerCase().includes(a);
        });
        console.log(x);
        setList(x)
        console.log(list);
    }

    //SORT
    function orderArray(){
        const x = list.reverse();
        const y = list.sort((a, b) => (a.messages.view > b.messages.view) ? 1 : -1);
        console.log(y);
    }

    function sortMessagesByView() {
        const x = list.sort(function(a, b) {
          if (a.messages.some(m => m.view === 'no') && !b.messages.some(m => m.view === 'no')) {
            return -1;
          } else if (!a.messages.some(m => m.view === 'no') && b.messages.some(m => m.view === 'no')) {
            return 1;
          } else {
            return 0;
          }
        });

        console.log(x)
      }

    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt1}</h1>

                <div className="list_box2">
                    <h3>{txt2}</h3>
                    {
                    list2.length == 0
                    ?
                    <div className="loading_msg">{txt3}</div>
                    :
                    list2.map((item, index) => (
                        <Link to={`/Chat_contact/${ item.id }`} className="list_item"  key={index}>
                            <div className="list_data">
                                <p className="list_name">{ item.creator == activeuser.key_id ? txt7 + item.receptor_name : txt8 + item.creator_name}</p>
                                <p className="list_pos">{ item.creator == activeuser.key_id ? item.receptor_profile : item.creator_profile}</p>
                            </div>
                            <div className="clear"></div>
                        </Link>
                    ))}
                </div>

                <div className="list_box2">
                    <h3>{txt4}</h3>
                    <div className="search_box">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt5}
                            onChange={searching}
                        />
                    </div>
                    {
                    list.length == 0
                    ?
                    <div className="loading_msg">{txt6}</div>
                    :
                    list.map((item, index) => (
                        <Link to={`/Chat_contact/${ item.id }`} className="list_item"  key={index}>
                            <div className="list_data">
                                <p className="list_name">{ item.creator == activeuser.key_id ? txt7 + item.receptor_name : txt8 + item.creator_name}</p>
                                <p className="list_pos">{ item.creator == activeuser.key_id ? item.receptor_profile : item.creator_profile}</p>
                            </div>
                            <div className="clear"></div>
                        </Link>
                    ))}
                </div>
            </div>

        </div>
        </>

    );


}

export default Messages;