import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import Logo from '../images/logo.png';

import { DataContext } from '../firebase/datacontext.js';

const LoginReset = () => {
  
  //TEXTS IDIOMS
  var txt1 = "Reset your password";
  var txt2 = "RESET";
  var txt3 = "You will receive an email to reset your password";

  function changeLang(){
      var x = localStorage.getItem('idiom');

      if( x == "esp"){
          console.log("Lang: Español");
          txt1 = "Restaurar tu password";
          txt2 = "RESTAURAR";
          txt3 = "Recibirá un correo para restaurar su password";
  
      }
      else{
          console.log("Lang: English");
      }
  }

  changeLang();

  
  //
  const { resetPass } = useContext(DataContext);

  //
  const [mail, setMail] = useState();

  return (
    <div className="intro">

      <div className="log_box">
        <img src={Logo} alt="logo"/>
        <h1>{txt1}</h1>
        <p>{txt3}</p>
        <div className="fields">
          <div className="field">
            <label htmlFor="email">EMAIL:</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={e => setMail(e.target.value)}
            />
          </div>
        </div>
        <button className="btn" onClick={() => resetPass(mail)}>{txt2}</button>
      </div>
    
    </div>

  );
}

export default LoginReset;