import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Top from './top.js';

import { DataContext } from '../firebase/datacontext.js';

const Chat_offers = () => {

    //
    var u = window.location.pathname;
    var l = u.substr(13);
    console.log(u);
    console.log(l);

    //
    const [msg, setMsg] = useState('');
    const [ready, setReady] = useState(false);
    
    //
    var chatAuth = 'chat_auth';
    var chatNoauth ='chat_noauth';

    //
    const { saveMsgOffer, getMsgOffer, offerMsgs, activeuser} = useContext(DataContext);

    //TEXTS IDIOMS
    var txt1 = "OFFER MESSAGES CENTER";
    var txt2 = "To: ";
    var txt3 = "From: ";
    var txt4 = "Country: ";
    var txt5 = "League: ";
    var txt6 = "Salary: ";
    var txt7 = "Project: ";
    var txt8 = "No messages";
    var txt9 = "SEND";
    var txt10 = "Write a message";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            console.log("Lang: Español");
            txt1 = "CENTRO DE MENSAJES DE PROPUESTA";
            txt2 = "Para: ";
            txt3 = "De: ";
            txt4 = "País: ";
            txt5 = "Liga: ";
            txt6 = "Salario: ";
            txt7 = "Proyecto: ";
            txt8 = "Sin mensajes";
            txt9 = "ENVIAR";
            txt10 = "Escribe un mensaje";
        }
        else{
            console.log("Lang: English");
        }
    }

    changeLang();

    

    //
    async function postMsg(){
        var x = document.getElementById('chat_txt');
        if(x.value != ''){
            await saveMsgOffer(l, {
                text: msg,
                author: activeuser.key_id,
                name: activeuser.name + " " + activeuser.lastname
            });
            x.value = '';

        }else{
            alert("Escribe un mensaje");
        }  
    }

    //
    useEffect(() => {
     getMsgOffer(l);
     setReady(true)
    },[ready]);


    return(

        <>
           <Top/>
           <div className="Main">

                <div className="chat_window">
                    <div className="chat_title">
                        <h1>{txt1}</h1>
                        <h2>{offerMsgs.offer}</h2>
                        <div className="chat_info">
                            {
                                activeuser.key_id ==offerMsgs.creator
                                ?
                                <Link className="textlink" to={`/${ offerMsgs.receptor_profile }/${ offerMsgs.receptor }`}>{txt2} <strong>{offerMsgs.receptor_name}</strong></Link>
                                :
                                <Link className="textlink" to={`/${ offerMsgs.creator_profile }/${ offerMsgs.creator }`}>{txt3} <strong>{offerMsgs.creator_name}</strong></Link>
                            }
                            <div className="clear"></div>
                        </div>
                        <div className="chat_info">
                            <p>{txt4} <strong>{offerMsgs.offer_country}</strong></p>
                            <p>{txt5} <strong>{offerMsgs.league}</strong></p>
                            <p>{txt6} <strong>{offerMsgs.pay}</strong></p>
                            <p>{txt7} <strong>{offerMsgs.project}</strong></p>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="chat_area">
                        <div className="chat_box">
                            {
                                offerMsgs.length == 0
                                ?
                                <p>{txt8}</p>
                                :
                                offerMsgs.messages.map((item, index) => (
                                    <div key={index}>
                                        <div className={ activeuser.key_id == item.author ? chatAuth : chatNoauth }>
                                            <p className="chat_m">{item.text}</p> 
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                ))
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="chat_msg">
                            <input className="chat_txt" id="chat_txt"
                                type="text"
                                placeholder={txt10}
                                maxLength="50"
                                onChange={e => setMsg(e.target.value)}
                            />
                            <button className="chat_btn" onClick={postMsg}>{txt9}</button>
                            <div className="clear"></div>
                        </div>
                </div>
            
            </div>
        </>

    );

}

export default Chat_offers;